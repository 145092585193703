.checkout-container {
  position: relative;
  display: block;
  width: 100%;
  height: 84vh;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: bottom center;
  background-size: cover;
  box-sizing: border-box;
  margin-bottom: auto;
}

.pre-checkout {
  text-align: center;
  width: 100%;
  position: relative;
  padding: 3%;
  box-sizing: border-box;
}

.pre-checkout-options {
  width: 90%;
  height: auto;
  margin: 1% auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkout-option-box {
  position: relative;
  width: 50%;
  min-width: 400px;
  background-color: rgba(255, 255, 255, 0.3);
  height: auto;
  border: 1px solid #6d8cc3;
  padding: 3%;
  margin: 5%;
  box-sizing: border-box;
}

.checkout-option-content {
  margin: 10% 2%;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.user-checkout-form {
  text-align: left;
  width: 90%;
  overflow: auto;
}

.checkout-login-note {
  margin-top: 1em;
}

.checkout {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 4em;
}

.checkout-content {
  display: block;
  width: calc(100% - 250px);
}

.checkout-inner {
  width: 100%;
  height: 90%;
  overflow-y: auto;
}

.shipping-section {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  z-index: 1;
}

.shipping-content {
  margin-top: 1em;
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
}

.saved-address-section {
  margin: 0 1em;
  max-width: 50%;
  display: inline-block;
  box-sizing: border-box;
}

.saved-address-section.full-width {
  max-width: 100%;
}

.saved-addresses {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.address-option {
  display: block;
  padding: 1em 2em;
  margin: 0.5em;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #efb065;
}

.no-addresses {
  margin: 1em 0;
}

.new-address-section {
  margin: 0 1em;
  display: inline-block;
  width: 40%;
  box-sizing: border-box;
}

.address-container {
  display: inline-block;
}

.address-link {
  margin: 0.5em 1em;
}

.address-form-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.address-form-footer-button-container {
  margin-left: auto;
}

.shipping-button-container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.shipping-button {
  float: right;
}

.review-section-content {
  margin-bottom: 1em;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
}

.shipping-final-form {
  margin: 1em;
  display: flex;
  flex-direction: row;
}

.shipping-final-form-left {
  display: flex;
  flex-direction: column;
}

.shipping-radio-section {
  margin: 0 2em;
}

.review-button-container {
  float: right;
}

.payment-section {
  margin: 1em 0;
}

.payment-form-container {
  box-sizing: border-box;
  margin: 1em 2em;
  margin-right: 0;
}

.card-element {
  margin-bottom: 1em;
  min-width: 300px;
  max-width: 100%;
}

.stripe-button {
  background-color: #5f4158;
  padding: 0.8em 1.7em;
  font-size: 1em;
  margin: 1em;
  transition: 0.3s;
  color: #fff;
  font-family: 'Avenir Light', sans-serif;
  cursor: pointer;
  float: right;
}

.stripe-button:hover {
  background-color: #7a5472;
}

.checkout-side-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2em;
  min-width: 250px;
  height: auto;
}

.add-coupon-section {
  position: relative;
  margin-top: 5px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border: 2px solid #c296a8;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.coupon-button {
  position: relative;
  margin-left: auto;
}

.checkout-success-section {
  margin: 2em 0;
}

@media only screen and (max-width: 1000px) {
  .shipping-content,
  .shipping-final-form {
    flex-direction: column;
  }

  .saved-address-section {
    max-width: none;
    width: 100%;
  }

  .new-address-section {
    width: 90%;
  }

  .shipping-radio-section {
    margin: 0;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  div.checkout {
    flex-wrap: wrap;
  }

  div.checkout-content {
    width: 100%;
  }

  aside.checkout-side-section {
    margin: 30px auto 0 auto;
    position: relative;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  div.checkout {
    padding: 20px;
  }

  .payment-form-container {
    margin-left: 0;
  }

  .card-element {
    min-width: 200px;
  }
}

@media only screen and (max-width: 400px) {
  .checkout-option-box {
    min-width: 300px;
  }
}
