.mini-room-gallery {
  display: flex;
}

.mini-room-gallery.flow-right {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 440px;
}

.mini-room-gallery.flow-down {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 380px;
}

.mini-room-gallery-thumbnails {
  display: flex;
}

.mini-room-gallery-thumbnails.flow-right {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5%;
  flex-wrap: wrap;
}

.mini-room-gallery-thumbnails.flow-down {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -20px;
}

.mini-room-gallery-thumbnail {
  height: 2.2em;
  margin: 0.25em;
}
