.customize-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* height: 70%; */
}

.customize-heading {
  text-align: center;
}

.visible-customization-container {
  width: 100%;
  max-height: 70vh;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
}

.pixelated-container {
  width: 55%;
  /*originally 40%*/
  height: 80%;
  display: block;
  padding-top: 1.5em;
}

.customizable-image {
  display: block;
  margin: 0 auto;
}

.sliders-container {
  width: 100%;
  height: 46vh;
}

.slider-inner-container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sliders {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.revert-area {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.revert-button {
  cursor: pointer;
  color: #36284c;
  font-size: 1.5em;
  transition: 0.4s;
}

.revert-button:hover {
  color: #111;
}

.revert-icon {
  height: 1.3em;
}

.edit-blurb {
  width: 90%;
  margin: 0 10px;
  text-align: center;
}

.edit-blurb-heading {
  text-align: center;
  font-weight: bold;
}

.customization-right {
  width: 45%;
  /*originally 50%*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customization-left {
  display: flex;
  flex-direction: column;
}

.advanced-settings-label {
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .visible-customization-container {
    flex-wrap: wrap;
    max-height: none;
  }

  .customization-right {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .pixelated-container {
    width: 100%;
  }

  .customize-header {
    font-size: 28px;
    text-align: center;
    padding: 0 20px;
  }

  .customizable-image {
    margin: 0 auto 20px auto;
  }

  .slider-label-container,
  .slider {
    width: 80% !important;
  }

  .loading-section {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 600px) {
  .visible-customization-container {
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: center;
  }
}

.loading-section {
  margin-top: 30px;
  text-align: center;
}