header {
  --header-height: 72px;

  height: var(--header-height);
  width: 100vw;
  margin: 0;
  left: 0;
  top: 0;
  /* position: relative; */
  position: fixed;
  z-index: 1000;
  background-color: #fff;
}

div.header-wrapper {
  position: relative;
  width: 100%;
  height: var(--header-height);
  line-height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  z-index: 1000;
}

.header-item {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.navbar-item {
  margin: 16px;
  margin-left: auto;
}

.header-logo {
  width: 240px;
  height: auto;
}

/* for tablets */
@media only screen and (max-width: 768px) {
  header {
    height: 55px;
  }

  div.header-wrapper {
    height: 55px;
    padding: 8px;
  }

  .header-logo {
    width: 190px;
  }
}
