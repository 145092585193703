div.hamburger {
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1002;
}

div.hamburger-line {
  width: 30px;
  height: 4px;
  background: #000;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

div.hamburger-line:first-child {
  transform: rotate(0);
}

div.hamburger-line:nth-child(2) {
  opacity: 1;
  transform: translateX(0);
}

div.hamburger-line:nth-child(3) {
  transform: rotate(0);
}

div.hamburger-line:first-child.open {
  transform: rotate(45deg);
}

div.hamburger-line:nth-child(2).open {
  opacity: 0;
  transform: translateX(20px);
}

div.hamburger-line:nth-child(3).open {
  transform: rotate(-45deg);
}
