.home-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1608657973/brixpix-assets/backgrounds/HomePage_-_WebBackGrounds_mgepn7.jpg');
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: nowrap;
  position: relative;
  overflow-y: auto;
  align-items: flex-start;
}

.home-page-inner {
  height: inherit;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .home-page {
    min-height: calc(100vh - 55px);
    height: auto;
  }
}

/* large devices */
@media only screen and (min-width: 769px) {
  div.home-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

div.homepage-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 10px;
}

.homepage-text {
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  font-size: 30px;
}

.start-button {
  padding: 5px;
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

div.home-image-section {
  display: grid;
  width: 80%;
  margin: 0 auto;
  position: relative;
}

div.home-image-section.desktop-home-image-section {
  grid-template-columns: 28% 5% 33% 5% 28%;
}

div.home-image-section.mobile-home-image-section {
  grid-template-columns: 45% 55%;
  grid-template-rows: 150px 250px 150px;
  margin: 20px 0;
  width: 100%;
}

.home-fade-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .home-fade-container {
    padding: 0 10px;
    box-sizing: border-box;
  }
}

.home-fade-container.left-section {
  justify-content: flex-end;
}

.home-fade-container.center-section {
  justify-content: center;
}

.home-fade-container.right-section {
  justify-content: flex-start;
}

.home-fade-container.top-section {
  padding-bottom: 10%;
}

.home-fade-container.bottom-section {
  padding-top: 10%;
}

.home-fade-container :not(.home-arrow) {
  z-index: 10;
}

.home-arrow.down-right-arrow {
  position: absolute;
  left: 45%;
  top: 17%;
  width: 70px;
}

.home-arrow.down-left-arrow {
  position: absolute;
  left: 45%;
  top: 70%;
  width: 70px;
}

@media only screen and (max-width: 500px) {
  .home-arrow.down-right-arrow {
    top: 18%;
  }

  .home-arrow.down-left-arrow {
    top: 65%;
  }
}

img.brix-pieces {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 250px;
}

@media only screen and (max-width: 1000px) {
  img.brix-pieces {
    max-height: 180px;
  }
}

@media only screen and (max-width: 768px) {
  img.brix-pieces {
    position: absolute;
    max-width: 110%;
    max-height: 280px;
  }
}

@media only screen and (max-width: 500px) {
  img.brix-pieces {
    max-height: 220px;
  }
}

.home-banner {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #6d8cc3b0;
  padding: 5px;
  margin: 20px 10px 10px 10px;
  margin-left: auto;
  margin-right: auto;
}

h3.homepage-end {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1200px) {
  div.home-page > h3 {
    margin-left: auto;
    text-align: right;
  }

  div.homepage-copy > div > p {
    font-size: 1.2em;
  }

  div.homepage-copy > div > h2 {
    font-size: 2em;
  }
}

@media only screen and (max-width: 1000px) {
  div.home-page > h3 {
    font-size: 1.6em;
  }

  div.homepage-copy > div > p {
    font-size: 1em;
  }

  div.homepage-copy > div > h2 {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 768px) {
  div.main-slider-page {
    padding: 20px 0;
  }

  h3.homepage-end {
    padding: 0 30px;
  }

  div.homepage-copy {
    flex-wrap: wrap;
    padding: 0 20px;
  }

  div.homepage-top {
    grid-template-columns: 1fr;
  }
}
