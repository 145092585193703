.pricing-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1608670753/brixpix-assets/backgrounds/Pricing_Page_-_WebBackGrounds_tz9tco.jpg');
  background-position: center;
  background-size: cover;
  overflow-y: auto;
}

@media only screen and (min-width: 769px) {
  div.pricing-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

.pricing-inner {
  height: inherit;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.pricing-section {
  text-align: center;
  max-width: 70%;
  min-width: 90px;
  overflow-y: auto;
  box-sizing: border-box;
}

.pricing-table-container {
  overflow-y: scroll;
}

.pricing-table-header {
  display: grid;
  grid-template-columns: 40% 30% 30%;
}

.pricing-table {
  display: grid;
  grid-template-columns: 40% 30% 30%;
}

.pricing-inner {
  padding: 5px 15px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1200px) {
  div.pricing-section > h1 {
    font-size: 1.8em;
  }
}

@media only screen and (max-width: 1000px) {
  div.pricing-right {
    padding: 10px;
  }
}

@media only screen and (max-width: 768px) {
  div.pricing-inner {
    flex-wrap: wrap;
  }

  div.pricing-right {
    text-align: center;
  }
}
