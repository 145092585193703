.contact {
  position: relative;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.contact-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 3em);
  height: 100%;
  margin-left: 3em;
}

.contact-notes {
  max-width: 30%;
  min-width: 300px;
  height: 100%;
  padding: 3em;
  padding-top: 7em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contact-main {
  width: 60%;
  height: 100%;
  box-sizing: border-box;
  padding: 2em 3em;
}

.contact-form {
  margin: 1em 1.5em;
  min-width: 70%;
  max-width: 300px;
  height: 380px;
  overflow-y: auto;
  box-sizing: border-box;
}

.contact-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.contact-success {
  width: 80%;
  margin: 50px auto;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  div.contact-content {
    flex-wrap: wrap;
  }

  div.contact-notes {
    min-width: 90%;
    margin: 0 auto;
    padding: 40px 20px 0 20px;
    text-align: center;
    justify-content: flex-start;
    height: auto;
  }

  div.contact-notes-main {
    padding-bottom: 20px;
  }

  div.contact-main {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  form.contact-form {
    margin: 0 auto;
    min-width: 90%;
  }
}
