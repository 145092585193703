.slider-page {
  width: 100%;
  height: calc(calc(100vh - 72px) - 42px);
  box-sizing: border-box;
  display: flex;
  overflow: auto;
}

.orders-page,
.coupons-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.orders-container,
.coupons-container {
  height: auto;
  width: 100%;
  position: relative;
  padding: 15px;
  box-sizing: border-box;
}

.admin-header {
  margin: 2em;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.view-selector {
  position: absolute;
  top: 70px;
  left: 30px;
}

div.admin-order-header,
div.admin-order-table {
  position: relative;
  display: grid;
  grid-template-columns: 8em 6em 9em 10em 10em 10em 9em 9em 8em;
  /* grid-template-columns: minmax(8em, 25%) minmax(6em, 25%) 9em minmax(10em, 25%) 10em 10em minmax(8em, 25%); */
  width: 90%;
  box-sizing: border-box;
  margin: 0 auto;
}

div.admin-order-table {
  min-height: 50%;
}

.admin-coupons-header,
.admin-coupons-table {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 12em 8em;
  width: 90%;
  box-sizing: border-box;
  margin: 0 auto;
}

.admin-coupons-table {
  max-height: 50vh;
  overflow: auto;
}

.admin-expand {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.center-align-col > div > .admin-expand {
  margin-left: 20%;
  justify-content: center;
}

.admin-expand-icon {
  height: 6px;
  margin: 0 10px;
}

.pdf-section {
  transition: 0.4s;
  overflow: hidden;
}

.pdf-section.expanded {
  max-height: 300px;
}

.collapsed {
  max-height: 0;
}

.tracking-editor,
.coupon-editor {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.finish-icon-section {
  display: flex;
  flex-direction: row;
}

.admin-edit-icon {
  height: 12px;
  margin: 0 10px;
  cursor: pointer;
}

.admin-finish-icon {
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
}

.admin-finish-icon.big-finish-icon {
  height: 20px;
}

.new-coupon-button {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

@media only screen and (max-width: 1200px) {
  div.admin-order-header,
  div.admin-order-table {
    width: 100%;
  }

  div.admin-order-header > div > p {
    font-size: 1em;
  }
}

@media only screen and (max-width: 880px) {
  div.view-selector {
    top: 20px;
  }

  div.admin-header {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 768px) {
  div.admin-order-header,
  div.admin-order-table {
    grid-template-columns: 1.5fr 1fr 2fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  div.admin-order-header > div > p,
  div.admin-order-table {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 400px) {
  div.view-selector {
    left: 5px;
  }
}
