div.image-selector {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50%;
}

img.image-selector-arrow {
  height: 100px;
  margin: 10px 20px;
}

img.image-selector-image {
  height: 70%;
}

@media only screen and (max-width: 600px) {
  img.image-selector-arrow {
    height: 60px;
    margin: 10px;
  }
}

@media only screen and (max-width: 400px) {
  img.image-selector-image {
    height: 50%;
  }
}
