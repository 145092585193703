input,
label {
  font-family: 'Avenir Light', sans-serif;
}

.text-input-container {
  margin: 0.4em;
  margin-bottom: 0;
  width: 100%;
  position: relative;
}

label {
  display: block;
  font-size: 0.9em;
  margin: 0;
  margin-top: 0.5em;
  color: #3578bd;
}

.text-input {
  outline: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #3578bd;
  padding: 0.2em;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.form-note {
  margin: 0 0.5em;
}

.text-input ~ .text-input-focus-border {
  position: absolute;
  top: 3em;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #3578bd;
  transition: 0.4s;
}

.text-input:focus ~ .text-input-focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}

.text-input ~ .text-input-focus-border.no-label-focus {
  top: 1.4em;
}

.text-input ~ .text-input-focus-border.short-focus {
  top: 2.6em;
}

.input-icon-container {
  width: 2em;
  display: flex;
  align-items: flex-end;
}

.input-icon {
  width: 2em;
  margin-bottom: 0.5em;
}

.radio-button-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.radio-content-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radio-button {
  position: relative;
  margin: 1.3em;
}

.radio-button.cramped-radio-button {
  margin: 0 1.3em;
}

.outer-radio-button {
  position: relative;
  width: 1.8em;
  height: 1.8em;
  border-radius: 100%;
  border: 7px solid #efb065;
  box-sizing: border-box;
  background-color: transparent;
}

.inner-radio-button {
  width: 0.7em;
  height: 0.7em;
  border-radius: 100%;
  position: absolute;
  left: 0.55em;
  background-color: #efb065;
  top: 0.55em;
}

.radio-button-container.tiny-radio-button
  > .radio-content-container
  > .radio-button {
  margin: 0.1em 0.3em;
}

.radio-button-container.tiny-radio-button
  > .radio-content-container
  > .radio-button
  > .outer-radio-button {
  width: 0.7em;
  height: 0.7em;
  border: 1px solid #fff;
}

.radio-button-container.tiny-radio-button
  > .radio-content-container
  > .radio-button
  > .inner-radio-button {
  left: 2.5px;
  top: 2.5px;
  width: 0.4em;
  height: 0.4em;
  background-color: #fff;
}

.radio-button-sublabel {
  margin-left: 3.6em;
}

.radio-button-sublabel.indented-sublabel {
  margin-left: 4.4em;
}

.radio-button-container.inline-radio-button {
  display: inline-flex;
}

.radio-button-container.inline-radio-button
  > .radio-content-container
  > .radio-button,
.radio-button-container.small-radio-button
  > .radio-content-container
  > .radio-button {
  margin: 0.7em;
}

.radio-button-container.inline-radio-button
  > .radio-content-container
  > .radio-button
  > .outer-radio-button,
.radio-button-container.small-radio-button
  > .radio-content-container
  > .radio-button
  > .outer-radio-button {
  width: 1.2em;
  height: 1.2em;
  border: 4px solid #6d8cc3;
}

.radio-button-container.inline-radio-button
  > .radio-content-container
  > .radio-button
  > .inner-radio-button,
.radio-button-container.small-radio-button
  > .radio-content-container
  > .radio-button
  > .inner-radio-button {
  width: 0.4em;
  height: 0.45em;
  background-color: #6d8cc3;
  left: 0.41em;
  top: 0.35em;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.checkbox {
  margin: 1em;
  position: relative;
}

.outer-checkbox {
  height: 1.1em;
  width: 1.1em;
  background-color: transparent;
  border: 2px solid #6d8cc3;
  box-sizing: border-box;
}

.inner-checkbox {
  position: absolute;
  top: 0.27em;
  left: 0.25em;
  height: 0.65em;
  width: 0.6em;
  z-index: 100;
  background-color: #6d8cc3;
}

.checkbox-main-label {
  margin-top: 1.5em;
}

input.quantity-input {
  border: 1px solid #36284c;
  display: inline-block;
  padding: 3% 5%;
  text-align: right;
  width: 2em;
  font-size: 1.3em;
  margin: 5% 2%;
}

.select-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.5em;
  position: relative;
}

.select-wrapper {
  height: auto;
  width: auto;
  position: relative;
}

select.select-input {
  border: 1px solid #6d8cc3;
  padding: 0.5em 1em;
  padding-right: 2em;
  color: #555;
  font-family: 'Avenir Light', sans-serif;
  transition: 0.4s;
  width: auto;
  position: relative;
  z-index: 1;
}

select.select-input:focus {
  border: 2px solid #6d8cc3;
}

.text-area-container {
  margin: 1em 0.4em;
}

textarea.text-area {
  outline: none;
  resize: none;
  overflow: auto;
  width: 100%;
  height: 5em;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #6d8cc3;
  margin-top: 0.5em;
  font-family: 'Avenir Light', sans-serif;
  color: #555;
}

textarea.text-area:focus {
  border: 2px solid #6d8cc3;
}
