div.slider-menu {
  width: 200px;
  height: calc(100vh - 55px);
  box-sizing: border-box;
  position: absolute;
  top: 55px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
  background: rgba(255, 255, 255, 0.9);
  padding: 50px 20px 20px 20px;
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  transform: translateX(100%);
}

div.slider-menu.open {
  transform: translateX(0);
}

div.slider-overlay {
  position: absolute;
  top: 55px;
  right: 200px;
  background-color: rgba(102, 102, 102, 0.3);
  width: calc(100vw - 200px);
  height: calc(100vh - 55px);
  z-index: 101;
  transition: opacity 0.3s ease-in-out;
}

p.slider-menu-item {
  line-height: 2em !important;
  cursor: pointer;
}
