.not-found {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 84vh;
  position: relative;
}

.not-found-content {
  margin: 0 auto;
  padding-top: 5em;
  padding-left: 20%;
  width: 50%;
}

.not-found-copy {
  padding-left: 6em;
}

.not-found-text {
  margin: 0.75em 0;
}

.alert-404-container {
  display: inline-block;
  margin: 0.5em;
}

.alert-404 {
  width: 5em;
}

.alert-404-headings {
  display: inline-block;
}

@media only screen and (max-width: 1000px) {
  div.not-found-content {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  div.not-found-content {
    width: 90%;
    padding-left: 5%;
  }
}

@media only screen and (max-width: 600px) {
  div.not-found-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    height: 100%;
  }

  div.not-found-copy,
  div.alert-404-headings {
    padding: 0;
    text-align: center;
  }

  img.alert-404,
  div.alert-404-container {
    display: block;
    margin: 10px auto;
  }
}
