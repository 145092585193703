aside.account-sidebar {
  width: 20%;
  min-width: 170px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 15px 20px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}

div.account-desktop-menu {
  margin: 35px 0;
}

p.account-desktop-menu-item {
  margin: 1em;
  transition: 0.4s;
}

p.account-desktop-menu-item:hover {
  color: #4f3b6e;
}

div.account-top-menu {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #6d8cc3;
  position: relative;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  z-index: 1000;
}

div.account-icon-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

img.account-menu-icon {
  margin: 0 8px;
}

.account-menu-welcome {
  margin-left: auto;
}

div.account-top-menu-container {
  position: relative;
}

div.account-menu-dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
}

div.account-dropdown-option {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #efb065;
  position: relative;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  z-index: 100;
}

div.account-dropdown-option.focused {
  background-color: #fccb90;
}
