.image-carousel {
  width: 100%;
  /* height: 12vh; */
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.carousel-arrow-container {
  width: 2em;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-arrow {
  width: 40%;
}

.carousel-image-section {
  overflow: hidden;
  position: relative;
  width: 80%;
  height: 3.8em;
}

.carousel-moving-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  transition: left 1s linear;
}
