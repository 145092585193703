.mini-gallery {
  display: inline-flex;
  flex-direction: column;
  margin: 1em;
}

.mini-gallery-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.mini-gallery-options.small {
  justify-content: center;
}

.mini-gallery-thumbnail {
  height: 3em;
  margin: 4px;
}

.mini-gallery-thumbnail.small {
  height: 2em;
  margin: 3px;
}
