.price-sidebar {
  width: 100%;
  height: auto;
  padding: 25px;
  padding-bottom: 15px;
  box-sizing: border-box;
  border: 2px solid #efb065;
}

.sidebar-pricing-table {
  display: grid;
  width: 100%;
  grid-template-columns: 60% 40%;
}

.sidebar-pricing-cell {
  padding: 0.25em;
}

.sidebar-pricing-special {
  padding: 0.5em 0.25em;
}
