.signup-container {
  position: relative;
  margin-left: 3em;
  width: calc(100vw - 3em);
  height: 100%;
  overflow-y: auto;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.signup-form-container {
  position: relative;
  box-sizing: border-box;
  width: 50%;
  height: auto;
  margin: 30px 15% 30px 35%;
  padding: 0 2em;
}

@media screen and (max-width: 800px) {
  .signup-form-container {
    margin: 0 auto;
    width: 70%;
  }
}

@media screen and (max-width: 500px) {
  .signup-form-container {
    margin: 0 auto;
    width: 90%;
  }

  .input-line.multi-field {
    flex-wrap: wrap;
  }
}

.signup-form {
  margin: 1em 2em;
  position: relative;
}

.input-line {
  display: flex;
  flex-direction: row;
}

.signup-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  div.signup-form-container {
    width: 60%;
    margin: 30px 15% 30px 25%;
  }
}

@media only screen and (max-width: 1000px) {
  div.signup-form-container {
    width: 70%;
    margin: 30px auto;
  }
}

@media only screen and (max-width: 768px) {
  div.signup-form-container {
    width: 90%;
    margin: 30px auto;
  }
}

@media only screen and (max-width: 600px) {
  h1.signup-title {
    font-size: 2.6em;
    line-height: 1em;
  }

  h1.signup-title,
  p.signup-subtitle {
    text-align: center;
    margin: 10px 0;
  }

  p.signup-bottom {
    text-align: center;
  }
}
