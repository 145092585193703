.account-page {
  width: 100%;
  height: 84vh;
  box-sizing: border-box;
  position: relative;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-size: cover;
  background-position: center;
}

.account-page-inner {
  width: 100%;
  height: inherit;
  padding-left: 3em;
  box-sizing: border-box;
  position: relative;
}

.account-content {
  width: 80%;
  max-width: calc(100% - 170px);
  height: 84vh;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.account-subpage-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.account-subpage {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 2em 4em;
  height: 80%;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}

.account-button-area {
  margin-top: auto;
  height: 20%;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 2em;
}

.account-button {
  display: inline-block;
}

.help-button-container {
  float: right;
}

.order-thumbnail {
  width: 90%;
  margin: 0 auto;
}

.item-thumbnail {
  width: 100%;
}

.tracking-cell {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 880px) {
  div.account-subpage {
    padding: 40px;
  }

  .account-welcome {
    font-size: 1.7em;
  }
}

@media only screen and (max-width: 768px) {
  div.account-content {
    height: auto;
    min-height: calc(calc(100vh - 55px) - 80px);
  }

  div.order-thumbnail-cell {
    margin: 8px;
  }

  div.account-content {
    width: 100%;
    max-width: 100%;
  }

  .account-header,
  .account-text {
    text-align: center;
  }

  div.account-subpage {
    padding: 40px 20px 0 20px;
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  div.help-button-container,
  div.back-button-container,
  div.order-again-button {
    float: none;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  div.order-again-button {
    margin-top: 30px;
  }
}
