.sliding-image {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;

  --medium-mult: 5;
  --small-mult: 4;
  --extra-small-mult: 3;
  --extra-extra-small-mult: 2;

  --landscape-height: 3em;
  --landscape-width: 4em;
  --portrait-height: 4em;
  --portrait-width: 3em;
  --square-height: 4em;
  --square-width: 4em;

  --side-margin: 3em;
}

.sliding-image.landscape-slider {
  height: calc(
    var(--medium-mult) * var(--landscape-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--medium-mult) * var(--landscape-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.portrait-slider {
  height: calc(
    var(--medium-mult) * var(--portrait-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--medium-mult) * var(--portrait-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.square-slider {
  height: calc(
    var(--medium-mult) * var(--square-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--medium-mult) * var(--square-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.landscape-slider.small-slider {
  height: calc(
    var(--small-mult) * var(--landscape-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--small-mult) * var(--landscape-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.portrait-slider.small-slider {
  height: calc(
    var(--small-mult) * var(--portrait-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--small-mult) * var(--portrait-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.square-slider.small-slider {
  height: calc(
    var(--small-mult) * var(--square-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--small-mult) * var(--square-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.landscape-slider.extra-small-slider {
  height: calc(
    var(--extra-small-mult) * var(--landscape-height) +
      calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--extra-small-mult) * var(--landscape-width) +
      calc(var(--side-margin) * 2)
  );
}

.sliding-image.portrait-slider.extra-small-slider {
  height: calc(
    var(--extra-small-mult) * var(--portrait-height) +
      calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--extra-small-mult) * var(--portrait-width) +
      calc(var(--side-margin) * 2)
  );
}

.sliding-image.square-slider.extra-small-slider {
  height: calc(
    var(--extra-small-mult) * var(--square-height) +
      calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--extra-small-mult) * var(--square-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.square-slider.extra-extra-small-slider {
  height: calc(
    var(--extra-extra-small-mult) * var(--square-height) +
      calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--extra-extra-small-mult) * var(--square-width) +
      calc(var(--side-margin) * 2)
  );
}

.sliding-image-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.sliding-image-frame.landscape-slider {
  width: calc(var(--medium-mult) * var(--landscape-width) + 5em);
}

.sliding-image-frame.portrait-slider {
  width: calc(var(--medium-mult) * var(--portrait-width) + 4em);
}

.sliding-image-frame.square-slider {
  width: calc(var(--medium-mult) * var(--square-width) + 5em);
}

.sliding-image-frame.landscape-slider.small-slider {
  width: calc(var(--small-mult) * var(--landscape-width) + 4em);
}

.sliding-image-frame.portrait-slider.small-slider {
  width: calc(var(--small-mult) * var(--portrait-width) + 3em);
}

.sliding-image-frame.square-slider.small-slider {
  width: calc(var(--small-mult) * var(--square-width) + 4em);
}

.sliding-image-frame.landscape-slider.extra-small-slider {
  width: calc(var(--extra-small-mult) * var(--landscape-width) + 3em);
}

.sliding-image-frame.portrait-slider.extra-small-slider {
  width: 11.6em;
  width: calc(var(--extra-small-mult) * var(--portrait-width) + 2.6em);
}

.sliding-image-frame.square-slider.extra-small-slider {
  width: 15em;
  width: calc(var(--extra-small-mult) * var(--square-width) + 3em);
}

.sliding-image-frame.square-slider.extra-extra-small-slider {
  width: 9em;
  width: calc(var(--extra-extra-small-mult) * var(--square-width) + 3em);
}

.image-under-slider {
  position: absolute;
  top: 50%;
}

.image-under-slider.landscape-slider,
.image-slider-container.landscape-slider {
  height: calc(var(--medium-mult) * var(--landscape-height));
  width: calc(var(--medium-mult) * var(--landscape-width));
}

.image-under-slider.portrait-slider,
.image-slider-container.portrait-slider {
  height: calc(var(--medium-mult) * var(--portrait-height));
  width: calc(var(--medium-mult) * var(--portrait-width));
}

.image-under-slider.square-slider,
.image-slider-container.square-slider {
  height: calc(var(--medium-mult) * var(--square-height));
  width: calc(var(--medium-mult) * var(--square-width));
}

.image-under-slider.landscape-slider.small-slider,
.image-slider-container.landscape-slider.small-slider {
  height: calc(var(--small-mult) * var(--landscape-height));
  width: calc(var(--small-mult) * var(--landscape-width));
}

.image-under-slider.portrait-slider.small-slider,
.image-slider-container.portrait-slider.small-slider {
  height: calc(var(--small-mult) * var(--portrait-height));
  width: calc(var(--small-mult) * var(--portrait-width));
}

.image-under-slider.square-slider.small-slider,
.image-slider-container.square-slider.small-slider {
  height: calc(var(--small-mult) * var(--square-height));
  width: calc(var(--small-mult) * var(--square-width));
}

.image-under-slider.landscape-slider.extra-small-slider,
.image-slider-container.landscape-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--landscape-height));
  width: calc(var(--extra-small-mult) * var(--landscape-width));
}

.image-under-slider.portrait-slider.extra-small-slider,
.image-slider-container.portrait-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--portrait-height));
  width: calc(var(--extra-small-mult) * var(--portrait-width));
}

.image-under-slider.square-slider.extra-small-slider,
.image-slider-container.square-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--square-height));
  width: calc(var(--extra-small-mult) * var(--square-width));
}

.image-under-slider.square-slider.extra-extra-small-slider,
.image-slider-container.square-slider.extra-extra-small-slider {
  height: calc(var(--extra-extra-small-mult) * var(--square-height));
  width: calc(var(--extra-extra-small-mult) * var(--square-width));
}

.resizing-wrapper {
  position: absolute;
  overflow: hidden;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 20;
  left: var(--side-margin);
}

.resizing-wrapper.landscape-slider,
.image-slider-thumb.landscape-slider {
  height: calc(var(--medium-mult) * var(--landscape-height));
}

.resizing-wrapper.portrait-slider,
.image-slider-thumb.portrait-slider {
  height: calc(var(--medium-mult) * var(--portrait-height));
}

.resizing-wrapper.square-slider,
.image-slider-thumb.square-slider {
  height: calc(var(--medium-mult) * var(--square-height));
}

.resizing-wrapper.landscape-slider.small-slider,
.image-slider-thumb.landscape-slider.small-slider {
  height: calc(var(--small-mult) * var(--landscape-height));
}

.resizing-wrapper.portrait-slider.small-slider,
.image-slider-thumb.portrait-slider.small-slider {
  height: calc(var(--small-mult) * var(--portrait-height));
}

.resizing-wrapper.square-slider.small-slider,
.image-slider-thumb.square-slider.small-slider {
  height: calc(var(--small-mult) * var(--square-height));
}

.resizing-wrapper.landscape-slider.extra-small-slider,
.image-slider-thumb.landscape-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--landscape-height));
}

.resizing-wrapper.portrait-slider.extra-small-slider,
.image-slider-thumb.portrait-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--portrait-height));
}

.resizing-wrapper.square-slider.extra-small-slider,
.image-slider-thumb.square-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--square-height));
}

.resizing-wrapper.square-slider.extra-extra-small-slider,
.image-slider-thumb.square-slider.extra-extra-small-slider {
  height: calc(var(--extra-extra-small-mult) * var(--square-height));
}

.original-slider-image {
  z-index: 20;
  left: var(--side-margin);
  transform: translate(calc(-1 * var(--side-margin)), -50%);
}

.original-slider-image.landscape-slider {
  width: calc(var(--regular-mult) * var(--landscape-width));
}

.original-slider-image.portrait-slider {
  width: calc(var(--regular-mult) * var(--portrait-width));
}

.original-slider-image.square-slider {
  width: calc(var(--regular-mult) * var(--square-width));
}

.original-slider-image.landscape-slider.small-slider {
  width: calc(var(--small-mult) * var(--landscape-width));
}

.original-slider-image.portrait-slider.small-slider {
  width: calc(var(--small-mult) * var(--portrait-width));
}

.original-slider-image.square-slider.small-slider {
  width: calc(var(--small-mult) * var(--square-width));
}

.original-slider-image.landscape-slider.extra-small-slider {
  width: calc(var(--extra-small-mult) * var(--landscape-width));
}

.original-slider-image.portrait-slider.extra-small-slider {
  width: calc(var(--extra-small-mult) * var(--portrait-width));
}

.original-slider-image.square-slider.extra-small-slider {
  width: calc(var(--extra-small-mult) * var(--square-width));
}

.original-slider-image.square-slider.extra-extra-small-slider {
  width: calc(var(--extra-extra-small-mult) * var(--square-width));
}

.pixelated-slider-image {
  z-index: 10;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-slider-container {
  position: relative;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
}

.image-slider {
  width: 100%;
  z-index: 50;
}

.image-slider-thumb {
  width: 4px;
  text-align: center;
  background-color: #c296a8;
  color: transparent;
  cursor: grab;
}

.slider-handle {
  position: absolute;
  top: 70%;
  transform: translateX(0);
  cursor: grab;
  width: 20px;
}

.slider-handle.landscape-slider {
  left: 47%;
}

.slider-handle.portrait-slider {
  left: 46%;
}

.slider-handle.square-slider {
  left: 46%;
}

.slider-handle.landscape-slider.small-slider {
  left: 46%;
}

.slider-handle.portrait-slider.small-slider {
  left: 45%;
}

.slider-handle.square-slider.small-slider {
  left: 45%;
}

.slider-handle.portrait-slider.extra-small-slider {
  left: 43%;
}

.slider-handle.landscape-slider.extra-small-slider {
  left: 44.5%;
}

.slider-handle.square-slider.extra-small-slider {
  left: 45%;
}

.slider-handle.square-slider.extra-extra-small-slider {
  left: 43%;
}
