.disabled {
  cursor: not-allowed;
}

.classic-button-container {
  display: inline-block;
  margin: 1em;
}

.classic-button {
  background-color: #6d8cc3;
  color: #fff;
  padding: 0.8em 1.7em;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.classic-button.disabled {
  background-color: #888;
  cursor: not-allowed;
}

.classic-button.disabled:hover {
  background-color: #888;
}

.classic-button.purple-background:hover {
  background-color: #4f3b6e;
}

.classic-button:hover,
.classic-button.blue-background:hover {
  background-color: #84a3d9;
}

.classic-button.eggplant-background:hover {
  background-color: #7a5472;
}

.classic-button.rose-background:hover {
  background-color: #e6b1c7;
}

.classic-button.gold-background:hover {
  background-color: #ffc175;
}

.brix-button {
  position: relative;
  margin: 1em 0;
  cursor: pointer;
  position: relative;
  line-height: normal;
  font-size: 1.1em;
  color: #fff;
  transition: 0.4s;
  box-sizing: border-box;
  width: 4.5em;
}

.brix-button-body {
  position: relative;
  padding: 0.1em 0.2em;
  text-align: center;
}

.dot-container {
  position: relative;
  width: 100%;
  height: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.brix-dot {
  width: 2em;
  height: 0.5em;
  transition: 0.4s;
}

div.inactive-button {
  cursor: not-allowed;
}

.brix-dot,
.brix-dot.blue-brix {
  background-color: #3578bd;
  background-image: linear-gradient(
    to right,
    rgba(109, 141, 195, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(109, 141, 195, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(47, 89, 143, 1);
}

.brix-dot.active,
.brix-dot.blue-brix.active {
  background-color: #5392d4;
  background-image: linear-gradient(
    to right,
    rgba(119, 153, 212, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(119, 153, 212, 1)
  );
}

.brix-dot.purple-brix {
  background-color: #4a3d69;
  background-image: linear-gradient(
    to right,
    rgba(68, 40, 112, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(68, 40, 112, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(74, 61, 105, 1);
}

.brix-dot.purple-brix.active {
  background-color: #9277d1;
  background-image: linear-gradient(
    to right,
    rgba(82, 42, 145, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(82, 42, 145, 1)
  );
}

.brix-dot.rose-brix {
  background-color: #95337b;
  background-image: linear-gradient(
    to right,
    rgba(194, 150, 168, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(194, 150, 168, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(105, 41, 88, 1);
}

.brix-dot.rose-brix.active {
  background-color: #bf479f;
  background-image: linear-gradient(
    to right,
    rgba(204, 155, 175, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(204, 155, 175, 1)
  );
}

.brix-dot.black-brix {
  background-color: #1d181f;
  background-image: linear-gradient(
    to right,
    rgba(29, 24, 31, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(29, 24, 31, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(7, 6, 8, 1);
}

.brix-dot.black-brix.active {
  background-color: #575359;
  background-image: linear-gradient(
    to right,
    rgba(87, 83, 89, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(87, 83, 89, 1)
  );
}

.brix-dot.eggplant-brix {
  background-color: #1d181f;
  background-image: linear-gradient(
    to right,
    rgba(95, 65, 88, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(95, 65, 88, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(66, 46, 62, 1);
}

.brix-dot.eggplant-brix.active {
  background-color: #575359;
  background-image: linear-gradient(
    to right,
    rgba(107, 74, 100, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(107, 74, 100, 1)
  );
}

.brix-dot.gold-brix {
  background-color: #1d181f;
  background-image: linear-gradient(
    to right,
    rgba(239, 177, 101, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(239, 177, 101, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(150, 90, 21, 1);
}

.brix-dot.gold-brix.active {
  background-color: #575359;
  background-image: linear-gradient(
    to right,
    rgba(250, 189, 115, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(250, 189, 115, 1)
  );
}

div.inactive-button > a > div.dot-container > div.brix-dot {
  background-color: #bbb;
  background-image: linear-gradient(
    to right,
    #bbb,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.7),
    #bbb
  );
  box-shadow: inset 0 -8px 8px -5px #999;
}

.brix-button-body,
.brix-button-body.blue-brix {
  background-color: #3578bd;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(158, 181, 219, 1),
    rgba(109, 141, 195, 1)
  );
}

.brix-button-body.active,
.brix-button-body.blue-brix.active {
  background-color: #5392d4;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(158, 181, 219, 1),
    rgba(119, 153, 212, 1)
  );
}

.brix-button-body.purple-brix {
  background-color: #4a3d69;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(169, 145, 207, 1),
    rgba(68, 40, 112, 1)
  );
}

.brix-button-body.purple-brix.active {
  background-color: #9277d1;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(169, 145, 207, 1),
    rgba(82, 42, 145, 1)
  );
}

.brix-button-body.rose-brix {
  background-color: #95337b;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(219, 191, 202, 1),
    rgba(194, 150, 168, 1)
  );
}

.brix-button-body.rose-brix.active {
  background-color: #bf479f;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(219, 191, 202, 1),
    rgba(204, 155, 175, 1)
  );
}

.brix-button-body.black-brix {
  background-color: #1d181f;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(137, 134, 143, 1),
    rgba(29, 24, 31, 1)
  );
}

.brix-button-body.black-brix.active {
  background-color: #575359;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(137, 134, 143, 1),
    rgba(46, 45, 48, 1)
  );
}

.brix-button-body.eggplant-brix {
  background-color: #1d181f;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(120, 105, 117, 1),
    rgba(95, 65, 88, 1)
  );
}

.brix-button-body.eggplant-brix.active {
  background-color: #575359;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(120, 105, 117, 1),
    rgba(107, 74, 100, 1)
  );
}

.brix-button-body.gold-brix {
  background-color: #1d181f;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(237, 193, 140, 1),
    rgba(239, 177, 101, 1)
  );
}

.brix-button-body.gold-brix.active {
  background-color: #575359;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(237, 193, 140, 1),
    rgba(250, 189, 115, 1)
  );
}

div.inactive-button > a > div.button {
  background-image: radial-gradient(farthest-side at 0% 100%, #999, #bbb);
}

.sideways-button-container {
  position: relative;
  margin: 0.2em 0.4em;
}

.sideways-button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sideways-button-body {
  width: 6em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #fff;
  text-align: center;
  box-shadow: 5px 5px 10px 2px #444;
  z-index: 10;
  transition: 0.3s;
}

.sideways-button-body.small {
  width: 4.4em;
  height: 2.2em;
}

.sideways-button-body.small > p {
  font-size: 0.8em;
}

.sideways-button-dot {
  width: 0.5em;
  height: 1.5em;
  z-index: 15;
  transition: 0.3s;
}

.sideways-button-dot.small {
  width: 0.3em;
  height: 1em;
}

.sideways-button:hover > .sideways-button-body.purple-background,
.sideways-button:hover > .sideways-button-dot.purple-background {
  background-color: #4f3b6e;
}

.sideways-button:hover > .sideways-button-body.blue-background,
.sideways-button:hover > .sideways-button-dot.blue-background {
  background-color: #84a3d9;
}

.sideways-button:hover > .sideways-button-body.eggplant-background,
.sideways-button:hover > .sideways-button-dot.eggplant-background {
  background-color: #7a5472;
}

.sideways-button:hover > .sideways-button-body.rose-background,
.sideways-button:hover > .sideways-button-dot.rose-background {
  background-color: #e6b1c7;
}

.sideways-button:hover > .sideways-button-body.gold-background,
.sideways-button:hover > .sideways-button-dot.gold-background {
  background-color: #ffc175;
}

.sideways-button-body.disabled,
.sideways-button-dot.disabled {
  background-color: #888;
}
