/* FONTS */

@import url('https://fonts.googleapis.com/css2?family=Sanchez&display=swap');

/* GENERAL */

html,
body,
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  position: relative;
}

.App {
  position: relative;

  --mobile-width: 600px;
  --tablet-width: 768px;
  --small-desktop-width: 1000px;
  --medium-desktop-width: 1200px;
}

/* DEFAULT TAGS */

p b {
  font-family: 'Avenir', sans-serif;
}

p,
label,
div {
  font-family: 'Avenir Light', sans-serif;
}

a {
  text-decoration: none;
  transition: 0.3s;
  color: #c296a8;
}

h1,
h2 {
  font-family: 'Sanchez', serif;
  color: #36284c;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-family: 'Sanchez', serif;
  color: #6d8cc3;
  font-size: 2em;
}

h4 {
  font-family: 'Sanchez', serif;
  color: #6d8cc3;
  font-size: 1.6em;
}

h5 {
  font-family: 'Sanchez', serif;
  color: #6d8cc3;
  font-size: 1.2em;
}

h6 {
  font-family: 'Sanchez', serif;
  color: #6d8cc3;
  font-size: 0.9em;
}

p.extra-large-text {
  font-size: 2.5em;
  letter-spacing: 0.4px;
}

p.large-text {
  font-size: 1.3em;
  letter-spacing: 0.2px;
  line-height: 1.2em;
}

p.smallish,
li.smallish {
  font-size: 0.8em;
}

p.small,
li.small {
  font-size: 0.6em;
}

p.spacey-text {
  line-height: 2em;
}

/* COLORS */

.purple {
  color: #36284c;
}

.blue {
  color: #6d8cc3;
}

.eggplant {
  color: #5f4158;
}

.rose {
  color: #c296a8;
}

.gold {
  color: #efb065;
}

.dark-grey {
  color: #555;
}

.white {
  color: #fff;
}

.error,
.invalid {
  color: #99172a;
}

.purple-background {
  background-color: #36284c;
}

.blue-background {
  background-color: #6d8cc3;
}

.eggplant-background {
  background-color: #5f4158;
}

.rose-background {
  background-color: #c296a8;
}

.gold-background {
  background-color: #efb065;
}

/* BLOCK STYLES */

.underline {
  border-bottom: 1px solid #36284c;
}

.hidden {
  display: none;
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.sharp-canvas {
  image-rendering: pixelated;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: calc(calc(100vh - 72px) - 42px);
  box-sizing: border-box;
  margin-top: 72px;
}

.offscreen {
  position: absolute;
  left: -1000000px;
  top: -1000000px;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #95337b;
}

.arrow-up.rose {
  border-bottom: 10px solid #c296a8;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #95337b;
}

.arrow-down.white {
  border-top: 10px solid #fff;
}

.clickable {
  cursor: pointer;
}

.neutral-clickable {
  cursor: default;
}

.not-clickable {
  cursor: not-allowed;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.left {
  float: left;
}

.right {
  float: right;
}

.hidden-slider {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.visible-slider {
  max-height: 80vh;
  transition: all 0.8s ease-in-out;
}

.ordered-list {
  margin: 1em 0;
  display: block;
}

.ordered-list-item {
  display: grid;
  grid-template-columns: 2em 1fr;
  margin: 0.5em 0;
}

.flex-cell {
  display: flex;
  flex-direction: column;
}

.left-align-col {
  text-align: left;
  align-items: flex-start;
}

.center-align-col {
  text-align: center;
  align-items: center;
}

.right-align-col {
  text-align: right;
  align-items: flex-end;
}

.vertical-padded-cell {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.horizontal-padded-cell {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.padded-cell {
  margin: 1em;
}

.big-vertical-padded-cell {
  margin: 2.5em 0;
}

.vertical-centered-cell {
  justify-content: center;
}

.indented-col,
.indent {
  margin-left: 1.5em;
}

.tiny-bullet {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 0.2em;
}

.left {
  float: left;
}

.right {
  float: right;
}

.custom-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.custom-scroll::-webkit-scrollbar:vertical {
  width: 11px;
}

.custom-scroll::-webkit-scrollbar:horizontal {
  height: 11px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.3);
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.pixelated-background {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.big-icon {
  width: 5em;
  margin: 0 2em;
}

@media only screen and (max-width: 768px) {
  .page {
    margin-top: 55px;
    height: auto;
    min-height: calc(100vh - 55px);
  }
}

@media only screen and (max-width: 768px) {
  /* .only-mobile {
        display: block;
    } */

  .only-desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 768.1px) {
  .only-mobile {
    display: none !important;
  }

  /* .only-desktop {

    } */
}
