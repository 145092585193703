/* for desktops */
div.responsive-page {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 72px;
  overflow-y: auto;
}

@media only screen and (min-width: 769px) {
  div.responsive-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

/* for tablets */
@media only screen and (max-width: 768px) {
  div.responsive-page {
    margin-top: 55px;
  }
}
