.about-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1608657974/brixpix-assets/backgrounds/What_is_Brix_-_WebBackGrounds_ggdiup.jpg');
  background-position: center;
  background-size: cover;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
}

div.about-page-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  box-sizing: border-box;
}

@media only screen and (min-width: 769px) {
  div.about-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

.about-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-slider-container {
  padding: 0 3% 0 1%;
}

.about-copy-container {
  padding: 2% 3%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.about-end {
  margin: 30px 0;
}

@media only screen and (max-width: 1200px) {
  h4.about-end {
    font-size: 1.2em;
    margin: 20px 0;
  }

  div.about-copy-container > div > h2 {
    font-size: 1.8em;
  }
}

@media only screen and (max-width: 1000px) {
  div.about-copy-container > div > p {
    font-size: 1em;
  }

  div.about-page {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 880px) {
  div.about-slider-container {
    padding: 0;
  }

  div.about-copy-container > div > h2 {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 768px) {
  div.about-page,
  div.about-page-inner {
    flex-wrap: wrap;
    text-align: center;
  }

  div.about-left,
  div.about-copy-container {
    width: 100%;
  }
}
