.password-container {
  position: relative;
  margin-left: 3em;
  width: calc(100vw - 3em);
  min-height: calc(calc(100vh - 55px) - 80px);
  height: 100%;
  overflow-y: auto;
}

.password-form-container {
  position: relative;
  box-sizing: border-box;
  width: 50%;
  height: auto;
  margin: 50px 15% 50px 35%;
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.password-form {
  margin: 1em;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
}

.password-button-container {
  margin-left: auto;
  margin-top: 1em;
}

.password-padded {
  padding: 1em 0;
}

@media only screen and (max-width: 1000px) {
  div.password-form-container {
    width: 50%;
    margin: 50px auto;
  }

  form.password-form {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  div.password-form-container {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  div.password-form-container {
    width: 90%;
  }

  h1.password-title,
  p.password-subtitle,
  .password-text {
    text-align: center;
  }
}
