.slider {
  width: 60%;
}

.slider-track {
  top: 6px;
  bottom: 12.5px;
  height: 2px;
  background-color: #bbb;
  border-radius: 5px;
}

.slider-thumb {
  height: 10px;
  width: 10px;
  background-color: transparent;
  border: 2px solid #efb065;
  color: transparent;
  border-radius: 50%;
  cursor: grab;
}

.slider-thumb:focus {
  outline: 0;
}

.slider-label-container {
  width: 60%;
  margin: 0 auto;
  text-align: left;
  /* margin-bottom: -1em; */
}
