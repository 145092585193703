.room-tester {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.test-room {
  width: 100%;
  max-width: 500px;
  margin: 5% 0;
}

img.test-room.wide-room {
  width: 100%;
  min-width: 100%;
  margin: 0;
}

img.test-room.small-room {
  width: 100%;
  max-width: 300px;
}

.test-image-frame {
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
}

.test-image-frame.LL-frame {
  width: 250px;
  top: 14%;
}

/* outdated */
.test-image-frame.LM-frame {
  width: 340px;
  top: 20%;
}

/* outdated */
.test-image-frame.LS-frame {
  width: 260px;
  top: 20%;
}

.test-image-frame.PL-frame {
  width: 210px;
  top: 10%;
}

/* outdated */
.test-image-frame.PM-frame {
  width: 190px;
  top: 9%;
}

/* outdated */
.test-image-frame.PS-frame {
  width: 190px;
  top: 16%;
}

.test-image-frame.SL-frame {
  width: 250px;
  top: 7%;
}

/* outdated */
.test-image-frame.SM-frame {
  width: 260px;
  top: 12%;
}

/* outdated */
.test-image-frame.SS-frame {
  width: 170px;
  top: 20%;
}

.test-image {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}
