.order-history-content-container {
  height: auto;
  overflow: auto;
}

.order-history-content {
  display: grid;
  width: 70%;
  grid-template-columns: 20% 50% 30%;
}

@media only screen and (min-width: 1000px) {
  div.order-history-content-container {
    max-height: 60vh;
  }
}

@media only screen and (max-width: 1100px) {
  div.order-history-content {
    width: 85%;
  }
}

@media only screen and (max-width: 880px) {
  div.order-history-content {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  div.no-orders {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  p.large-text.order-history-header {
    font-size: 1.1em;
  }

  p.large-text.order-history-text {
    font-size: 1em;
  }

  div.order-history-content {
    grid-template-columns: 30% 33% 37%;
  }
}
