.edit-account {
  padding-bottom: 0;
}

.edit-account-form-container {
  height: 40vh;
  overflow: auto;
  margin: 1em;
}

.edit-account-form {
  width: 70%;
}

.edit-account-button-area {
  width: 80%;
}

.edit-account-button-container {
  float: right;
}

@media only screen and (max-width: 768px) {
  form.edit-account-form {
    width: 100%;
  }

  div.edit-account-form-container {
    height: auto;
  }

  div.edit-account-button-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
