div.fade-image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  --extra-small-size: 80px;
  --small-size: 140px;
  --medium-size: 200px;
  --large-size: 300px;
}

.extra-small-fade-image-container {
  width: var(--extra-small-size);
  height: var(--extra-small-size);
}

.small-fade-image-container {
  width: var(--small-size);
  height: var(--small-size);
}

.medium-fade-image-container {
  width: var(--medium-size);
  height: var(--medium-size);
}

.large-fade-image-container {
  width: var(--large-size);
  height: var(--large-size);
}

.fade-image {
  position: absolute;
  transition: 1s;
  height: auto;
}

.extra-small-fade-image {
  width: var(--extra-small-size);
}

.small-fade-image {
  width: var(--small-size);
}

.medium-fade-image {
  width: var(--medium-size);
}

.large-fade-image {
  width: var(--large-size);
}
