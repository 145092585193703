.shape-step {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shape-option-container {
  width: 70%;
}

.shape-option {
  display: grid;
  grid-template-columns: 40% 20% 40%;
  width: 100%;
  margin: 0;
  position: relative;
}

.shape-option-button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.example-shape-container {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.example-shape {
  background-color: #5f4158;
  box-shadow: 10px 10px 30px 3px #6d8cc3;
  margin: 1em;
}

.shape-description-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

@media only screen and (max-width: 1000px) {
  .shape-option-container {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .shape-option-button {
    min-width: 180px;
  }
}

@media only screen and (max-width: 600px) {
  .shape-option {
    grid-template-columns: 60% 40%;
  }

  .shape-description-container {
    display: none;
  }

  .shape-header {
    font-size: 28px;
    text-align: center;
  }

  .shape-option-container {
    width: 100%;
  }
}
