.upload-recommendation-box {
  margin: 2em;
}

.upload {
  width: auto;
  margin: 0 auto;
  text-align: center;
}

.upload-image-container {
  height: 45vh;
  position: relative;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#original-image {
  max-height: 100% !important;
}

.upload-button-container {
  min-width: 6em;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .upload-header {
    font-size: 28px;
    text-align: center;
  }

  .upload-subheader {
    padding: 15px 30px 0 30px;
    text-align: center;
  }

  .upload-text.large-text {
    font-size: 1em;
  }

  .upload-button-container {
    min-width: 6em;
    margin: 20px auto;
  }
}
