.reset-error {
  position: relative;
  width: 100%;
  min-height: calc(calc(100vh - 55px) - 80px);
  height: 100%;
  overflow-y: auto;
}

.reset-error-container {
  width: 50%;
  padding: 20px;
  margin: auto 15% auto 35%;
  display: flex;
  flex-direction: row;
}

.reset-error-text {
  display: flex;
  flex-direction: column;
}

.reset-error-button-container {
  margin: 1em 0 0 auto;
}

@media only screen and (max-width: 1000px) {
  div.reset-error-container {
    width: 70%;
    margin: auto 10% auto 20%;
  }
}

@media only screen and (max-width: 768px) {
  div.reset-error-container {
    width: 90%;
    margin: auto;
    padding-left: 0;
  }

  h2.reset-error-title {
    font-size: 2em;
  }
}
