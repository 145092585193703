div.blade {
  --blade-width: 3em;

  display: flex;
  position: fixed;
  width: var(--blade-width);
  border: 0;
  background-position: center bottom;
  background-size: cover;
  padding: 0;
  margin: 0;
  transition: 1s ease-in-out;
  box-sizing: border-box;
  z-index: 99;
}

@media only screen and (min-width: 769px) {
  div.blade {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

@media only screen and (max-width: 768px) {
  div.blade {
    height: calc(100vh - 55px);
  }
}

.blade-label-container {
  width: 3em;
  min-height: 10em;
  padding-top: 0.5em;
  position: relative;
  white-space: nowrap;
}

.blade-label {
  display: inline-block;
  color: #fff;
  position: relative;
  font-size: 1em;
}

div.blade.purple-blade {
  background-color: #4a3d69;
}

div.blade.blue-blade {
  background-color: #3578bd;
}

div.blade.purple-blade.blade1 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-01_ev59km.svg');
}

div.blade.purple-blade.blade2 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-02_zpiubl.svg');
}

div.blade.purple-blade.blade3 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-03_uf41xw.svg');
}

div.blade.purple-blade.blade4 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-04_qzlrk1.svg');
}

div.blade.purple-blade.blade5 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-05_xaxeaw.svg');
}

div.blade.blue-blade {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1630074265/brixpix-assets/blades/blade-blue_thr97s.png');
}
