div.main-page {
  --blade-width: 3em;
  --num-blades: 5;

  position: relative;
  display: block;
  width: 100%;
  overflow-y: auto;
}

div.main-slider-page {
  width: 100%;
}
