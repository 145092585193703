.step-header {
  position: relative;
  min-height: 80px;
  width: 35em;
  margin: 0 auto;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.step-header.small {
  width: 370px;
}

@media only screen and (max-width: 380px) {
  .step-header {
    margin-left: -10px;
  }
}

.order-process-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
  overflow-y: auto;
}

.step-selector-section {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: auto 1em 1.5em 1em;
  height: 3em;
}

.prev-step-button {
  margin-right: auto;
}

.next-step-button {
  margin-left: auto;
}
