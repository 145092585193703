.order-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1608657973/brixpix-assets/backgrounds/Start_Order_Page_-_WebBackGrounds_rnnobw.jpg');
  background-position: center;
  background-size: cover;
  padding: 15px;
  width: 80%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: auto;
}

@media only screen and (min-width: 769px) {
  div.order-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

.order-page-content {
  position: relative;
  box-sizing: border-box;
  margin: 0 100px;
  display: inline-flex;
  flex-direction: column;
}

.order-start-container {
  float: right;
}

.do-this-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.do-this-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 260px;
  box-sizing: border-box;
}

.do-this-pic {
  width: 200px;
}

@media only screen and (max-width: 1000px) {
  .order-page-content {
    margin: 0 50px;
  }
}

@media only screen and (max-width: 768px) {
  div.do-this-section {
    flex-direction: column;
    align-items: center;
  }

  div.order-page-content {
    text-align: center;
  }

  div.order-inner-content {
    font-size: 0.8em;
    text-align: center;
  }

  div.order-start-container {
    float: none;
    margin: 15px 0;
  }
}
