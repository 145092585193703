.divider-line-container {
  margin: 10px;
  margin-right: 0;
  width: 100%;
}

.divider-line {
  height: 1px;
  width: 100%;
  z-index: 100;
}
