.login-container {
  position: relative;
  box-sizing: border-box;
  margin-left: 3em;
  width: calc(100vw - 3em);
  height: 100%;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
  overflow-y: auto;
}

.login-form-container {
  position: relative;
  box-sizing: border-box;
  width: 50%;
  height: auto;
  margin: 0 15% 0 35%;
  padding: 50px 30px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.login-form {
  margin: 1em 2em;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.login-button-container {
  margin: 0 auto;
}

.login-padded {
  padding: 1em 0;
}

.login-alert-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.tiny-alert {
  width: 1em;
  margin: 0 0.5em;
  margin-bottom: 0.3em;
}

@media only screen and (max-width: 880px) {
  div.login-form-container {
    width: 70%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  div.login-form-container {
    width: 90%;
    margin: 0 auto;
  }

  h1.login-title,
  p.login-subtitle,
  div.login-bottom,
  p.login-bottom {
    text-align: center;
    margin: 10px 0;
  }

  div.login-bottom {
    margin: 0 auto;
  }

  div.login-padded {
    padding: 0;
  }
}
