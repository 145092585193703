.size-step {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.size-display {
  width: auto;
  padding: 0 2em;
  padding-top: 2em;
  height: 20em;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.size-preview-container {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 3em;
}

.image-placeholder {
  width: 20em;
  height: 12em;
  background-color: blue;
  margin: 5% 0;
}

.size-container {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.size-option-container {
  min-width: 342px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .size-option-container {
    min-width: 230px;
  }
}

@media only screen and (max-width: 600px) {
  .size-header {
    font-size: 28px;
    text-align: center;
  }

  .size-subheader {
    text-align: center;
    padding: 0 30px;
  }

  .size-display {
    padding-top: 0;
    flex-wrap: wrap;
    height: auto;
  }

  .size-option-container {
    margin: 20px auto;
  }

  .unit-container {
    margin: 20px auto;
  }

  .size-preview-container {
    margin: 0 auto;
  }
}
