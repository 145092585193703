@import url(https://fonts.googleapis.com/css2?family=Sanchez&display=swap);
div.blade {
  --blade-width: 3em;

  display: flex;
  position: fixed;
  width: var(--blade-width);
  border: 0;
  background-position: center bottom;
  background-size: cover;
  padding: 0;
  margin: 0;
  transition: 1s ease-in-out;
  box-sizing: border-box;
  z-index: 99;
}

@media only screen and (min-width: 769px) {
  div.blade {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

@media only screen and (max-width: 768px) {
  div.blade {
    height: calc(100vh - 55px);
  }
}

.blade-label-container {
  width: 3em;
  min-height: 10em;
  padding-top: 0.5em;
  position: relative;
  white-space: nowrap;
}

.blade-label {
  display: inline-block;
  color: #fff;
  position: relative;
  font-size: 1em;
}

div.blade.purple-blade {
  background-color: #4a3d69;
}

div.blade.blue-blade {
  background-color: #3578bd;
}

div.blade.purple-blade.blade1 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-01_ev59km.svg');
}

div.blade.purple-blade.blade2 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-02_zpiubl.svg');
}

div.blade.purple-blade.blade3 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-03_uf41xw.svg');
}

div.blade.purple-blade.blade4 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-04_qzlrk1.svg');
}

div.blade.purple-blade.blade5 {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606929181/brixpix-assets/blades/BrixBladeV5-05_xaxeaw.svg');
}

div.blade.blue-blade {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1630074265/brixpix-assets/blades/blade-blue_thr97s.png');
}

.blade-manager {
  --blade-width: 3em;

  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  z-index: 0;
  overflow: hidden;
}

.blade-manager-page {
  position: absolute;
  transition: 1s ease-in-out;
}

.blade-manager-page.left {
  -webkit-transform: translateX(calc(-1 * calc(100% - var(--blade-width))));
          transform: translateX(calc(-1 * calc(100% - var(--blade-width))));
}

.blade-manager-page.right {
  -webkit-transform: translateX(calc(100% - var(--blade-width)));
          transform: translateX(calc(100% - var(--blade-width)));
}

.disabled {
  cursor: not-allowed;
}

.classic-button-container {
  display: inline-block;
  margin: 1em;
}

.classic-button {
  background-color: #6d8cc3;
  color: #fff;
  padding: 0.8em 1.7em;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.classic-button.disabled {
  background-color: #888;
  cursor: not-allowed;
}

.classic-button.disabled:hover {
  background-color: #888;
}

.classic-button.purple-background:hover {
  background-color: #4f3b6e;
}

.classic-button:hover,
.classic-button.blue-background:hover {
  background-color: #84a3d9;
}

.classic-button.eggplant-background:hover {
  background-color: #7a5472;
}

.classic-button.rose-background:hover {
  background-color: #e6b1c7;
}

.classic-button.gold-background:hover {
  background-color: #ffc175;
}

.brix-button {
  position: relative;
  margin: 1em 0;
  cursor: pointer;
  position: relative;
  line-height: normal;
  font-size: 1.1em;
  color: #fff;
  transition: 0.4s;
  box-sizing: border-box;
  width: 4.5em;
}

.brix-button-body {
  position: relative;
  padding: 0.1em 0.2em;
  text-align: center;
}

.dot-container {
  position: relative;
  width: 100%;
  height: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.brix-dot {
  width: 2em;
  height: 0.5em;
  transition: 0.4s;
}

div.inactive-button {
  cursor: not-allowed;
}

.brix-dot,
.brix-dot.blue-brix {
  background-color: #3578bd;
  background-image: linear-gradient(
    to right,
    rgba(109, 141, 195, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(109, 141, 195, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(47, 89, 143, 1);
}

.brix-dot.active,
.brix-dot.blue-brix.active {
  background-color: #5392d4;
  background-image: linear-gradient(
    to right,
    rgba(119, 153, 212, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(119, 153, 212, 1)
  );
}

.brix-dot.purple-brix {
  background-color: #4a3d69;
  background-image: linear-gradient(
    to right,
    rgba(68, 40, 112, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(68, 40, 112, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(74, 61, 105, 1);
}

.brix-dot.purple-brix.active {
  background-color: #9277d1;
  background-image: linear-gradient(
    to right,
    rgba(82, 42, 145, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(82, 42, 145, 1)
  );
}

.brix-dot.rose-brix {
  background-color: #95337b;
  background-image: linear-gradient(
    to right,
    rgba(194, 150, 168, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(194, 150, 168, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(105, 41, 88, 1);
}

.brix-dot.rose-brix.active {
  background-color: #bf479f;
  background-image: linear-gradient(
    to right,
    rgba(204, 155, 175, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(204, 155, 175, 1)
  );
}

.brix-dot.black-brix {
  background-color: #1d181f;
  background-image: linear-gradient(
    to right,
    rgba(29, 24, 31, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(29, 24, 31, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(7, 6, 8, 1);
}

.brix-dot.black-brix.active {
  background-color: #575359;
  background-image: linear-gradient(
    to right,
    rgba(87, 83, 89, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(87, 83, 89, 1)
  );
}

.brix-dot.eggplant-brix {
  background-color: #1d181f;
  background-image: linear-gradient(
    to right,
    rgba(95, 65, 88, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(95, 65, 88, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(66, 46, 62, 1);
}

.brix-dot.eggplant-brix.active {
  background-color: #575359;
  background-image: linear-gradient(
    to right,
    rgba(107, 74, 100, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(107, 74, 100, 1)
  );
}

.brix-dot.gold-brix {
  background-color: #1d181f;
  background-image: linear-gradient(
    to right,
    rgba(239, 177, 101, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(239, 177, 101, 1)
  );
  box-shadow: inset 0 -8px 8px -5px rgba(150, 90, 21, 1);
}

.brix-dot.gold-brix.active {
  background-color: #575359;
  background-image: linear-gradient(
    to right,
    rgba(250, 189, 115, 1),
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9),
    rgba(250, 189, 115, 1)
  );
}

div.inactive-button > a > div.dot-container > div.brix-dot {
  background-color: #bbb;
  background-image: linear-gradient(
    to right,
    #bbb,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.7),
    #bbb
  );
  box-shadow: inset 0 -8px 8px -5px #999;
}

.brix-button-body,
.brix-button-body.blue-brix {
  background-color: #3578bd;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(158, 181, 219, 1),
    rgba(109, 141, 195, 1)
  );
}

.brix-button-body.active,
.brix-button-body.blue-brix.active {
  background-color: #5392d4;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(158, 181, 219, 1),
    rgba(119, 153, 212, 1)
  );
}

.brix-button-body.purple-brix {
  background-color: #4a3d69;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(169, 145, 207, 1),
    rgba(68, 40, 112, 1)
  );
}

.brix-button-body.purple-brix.active {
  background-color: #9277d1;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(169, 145, 207, 1),
    rgba(82, 42, 145, 1)
  );
}

.brix-button-body.rose-brix {
  background-color: #95337b;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(219, 191, 202, 1),
    rgba(194, 150, 168, 1)
  );
}

.brix-button-body.rose-brix.active {
  background-color: #bf479f;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(219, 191, 202, 1),
    rgba(204, 155, 175, 1)
  );
}

.brix-button-body.black-brix {
  background-color: #1d181f;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(137, 134, 143, 1),
    rgba(29, 24, 31, 1)
  );
}

.brix-button-body.black-brix.active {
  background-color: #575359;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(137, 134, 143, 1),
    rgba(46, 45, 48, 1)
  );
}

.brix-button-body.eggplant-brix {
  background-color: #1d181f;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(120, 105, 117, 1),
    rgba(95, 65, 88, 1)
  );
}

.brix-button-body.eggplant-brix.active {
  background-color: #575359;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(120, 105, 117, 1),
    rgba(107, 74, 100, 1)
  );
}

.brix-button-body.gold-brix {
  background-color: #1d181f;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(237, 193, 140, 1),
    rgba(239, 177, 101, 1)
  );
}

.brix-button-body.gold-brix.active {
  background-color: #575359;
  background-image: radial-gradient(
    farthest-side at 0% 100%,
    rgba(237, 193, 140, 1),
    rgba(250, 189, 115, 1)
  );
}

div.inactive-button > a > div.button {
  background-image: radial-gradient(farthest-side at 0% 100%, #999, #bbb);
}

.sideways-button-container {
  position: relative;
  margin: 0.2em 0.4em;
}

.sideways-button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sideways-button-body {
  width: 6em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #fff;
  text-align: center;
  box-shadow: 5px 5px 10px 2px #444;
  z-index: 10;
  transition: 0.3s;
}

.sideways-button-body.small {
  width: 4.4em;
  height: 2.2em;
}

.sideways-button-body.small > p {
  font-size: 0.8em;
}

.sideways-button-dot {
  width: 0.5em;
  height: 1.5em;
  z-index: 15;
  transition: 0.3s;
}

.sideways-button-dot.small {
  width: 0.3em;
  height: 1em;
}

.sideways-button:hover > .sideways-button-body.purple-background,
.sideways-button:hover > .sideways-button-dot.purple-background {
  background-color: #4f3b6e;
}

.sideways-button:hover > .sideways-button-body.blue-background,
.sideways-button:hover > .sideways-button-dot.blue-background {
  background-color: #84a3d9;
}

.sideways-button:hover > .sideways-button-body.eggplant-background,
.sideways-button:hover > .sideways-button-dot.eggplant-background {
  background-color: #7a5472;
}

.sideways-button:hover > .sideways-button-body.rose-background,
.sideways-button:hover > .sideways-button-dot.rose-background {
  background-color: #e6b1c7;
}

.sideways-button:hover > .sideways-button-body.gold-background,
.sideways-button:hover > .sideways-button-dot.gold-background {
  background-color: #ffc175;
}

.sideways-button-body.disabled,
.sideways-button-dot.disabled {
  background-color: #888;
}

.inner-checkbox {
  position: absolute;
  top: 0.25em;
  left: 0.25em;
  height: 0.6em;
  width: 0.6em;
  z-index: 100;
  background-color: #6d8cc3;
}

.divider-line-container {
  margin: 10px;
  margin-right: 0;
  width: 100%;
}

.divider-line {
  height: 1px;
  width: 100%;
  z-index: 100;
}

div.fade-image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  --extra-small-size: 80px;
  --small-size: 140px;
  --medium-size: 200px;
  --large-size: 300px;
}

.extra-small-fade-image-container {
  width: var(--extra-small-size);
  height: var(--extra-small-size);
}

.small-fade-image-container {
  width: var(--small-size);
  height: var(--small-size);
}

.medium-fade-image-container {
  width: var(--medium-size);
  height: var(--medium-size);
}

.large-fade-image-container {
  width: var(--large-size);
  height: var(--large-size);
}

.fade-image {
  position: absolute;
  transition: 1s;
  height: auto;
}

.extra-small-fade-image {
  width: var(--extra-small-size);
}

.small-fade-image {
  width: var(--small-size);
}

.medium-fade-image {
  width: var(--medium-size);
}

.large-fade-image {
  width: var(--large-size);
}

footer {
  height: 42px;
  overflow-y: auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 0.8fr 1.6fr 12em;
  z-index: 100;
  background-color: #fff;
}

.quick-links-area {
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: flex-start;
  padding: 0 5%;
  box-sizing: border-box;
}

.quick-link-container {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2em;
}

.footer-shipping-note {
  height: inherit;
  width: 100%;
  margin: 0 1em;
}

.social-area {
  margin-left: auto;
  height: inherit;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.social-icon-box {
  display: inline-flex;
  height: inherit;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.social-icon {
  height: 40px;
}

.social-link {
  position: relative;
  display: inline-block;
  height: 6vh;
}

@media only screen and (max-width: 930px) {
  .quick-links-area,
  .footer-shipping-note {
    text-align: center;
  }
}

/* for tablets */
@media only screen and (max-width: 768px) {
  footer,
  .quick-links-area,
  .quick-link-container,
  .footer-shipping-note,
  .social-icon-box {
    height: 80px;
  }
}

/* for mobile */
@media only screen and (max-width: 600px) {
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    height: auto;
  }

  .quick-links-area {
    justify-content: center;
    height: auto;
  }

  .quick-link-container {
    padding: 0.8em 0 0.2em 0;
    height: auto;
  }

  .footer-shipping-note {
    height: auto;
    padding: 0.2em 0;
  }

  .social-area,
  .social-icon-box {
    margin: 0.1em 0;
    height: auto;
  }
}

div.hamburger {
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1002;
}

div.hamburger-line {
  width: 30px;
  height: 4px;
  background: #000;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  -webkit-transform-origin: 1px;
          transform-origin: 1px;
}

div.hamburger-line:first-child {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

div.hamburger-line:nth-child(2) {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

div.hamburger-line:nth-child(3) {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

div.hamburger-line:first-child.open {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

div.hamburger-line:nth-child(2).open {
  opacity: 0;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}

div.hamburger-line:nth-child(3).open {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

div.slider-menu {
  width: 200px;
  height: calc(100vh - 55px);
  box-sizing: border-box;
  position: absolute;
  top: 55px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
  background: rgba(255, 255, 255, 0.9);
  padding: 50px 20px 20px 20px;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  z-index: 101;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

div.slider-menu.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

div.slider-overlay {
  position: absolute;
  top: 55px;
  right: 200px;
  background-color: rgba(102, 102, 102, 0.3);
  width: calc(100vw - 200px);
  height: calc(100vh - 55px);
  z-index: 101;
  transition: opacity 0.3s ease-in-out;
}

p.slider-menu-item {
  line-height: 2em !important;
  cursor: pointer;
}

header {
  --header-height: 72px;

  height: var(--header-height);
  width: 100vw;
  margin: 0;
  left: 0;
  top: 0;
  /* position: relative; */
  position: fixed;
  z-index: 1000;
  background-color: #fff;
}

div.header-wrapper {
  position: relative;
  width: 100%;
  height: var(--header-height);
  line-height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  box-sizing: border-box;
  z-index: 1000;
}

.header-item {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.navbar-item {
  margin: 16px;
  margin-left: auto;
}

.header-logo {
  width: 240px;
  height: auto;
}

/* for tablets */
@media only screen and (max-width: 768px) {
  header {
    height: 55px;
  }

  div.header-wrapper {
    height: 55px;
    padding: 8px;
  }

  .header-logo {
    width: 190px;
  }
}

.gallery-thumbnail {
  height: 3em;
  margin: 0.4em;
  /* box-sizing: content-box; */
  box-sizing: border-box;
}

.image-carousel {
  width: 100%;
  /* height: 12vh; */
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.carousel-arrow-container {
  width: 2em;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-arrow {
  width: 40%;
}

.carousel-image-section {
  overflow: hidden;
  position: relative;
  width: 80%;
  height: 3.8em;
}

.carousel-moving-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  transition: left 1s linear;
}

div.image-selector {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50%;
}

img.image-selector-arrow {
  height: 100px;
  margin: 10px 20px;
}

img.image-selector-image {
  height: 70%;
}

@media only screen and (max-width: 600px) {
  img.image-selector-arrow {
    height: 60px;
    margin: 10px;
  }
}

@media only screen and (max-width: 400px) {
  img.image-selector-image {
    height: 50%;
  }
}

.sliding-image {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;

  --medium-mult: 5;
  --small-mult: 4;
  --extra-small-mult: 3;
  --extra-extra-small-mult: 2;

  --landscape-height: 3em;
  --landscape-width: 4em;
  --portrait-height: 4em;
  --portrait-width: 3em;
  --square-height: 4em;
  --square-width: 4em;

  --side-margin: 3em;
}

.sliding-image.landscape-slider {
  height: calc(
    var(--medium-mult) * var(--landscape-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--medium-mult) * var(--landscape-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.portrait-slider {
  height: calc(
    var(--medium-mult) * var(--portrait-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--medium-mult) * var(--portrait-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.square-slider {
  height: calc(
    var(--medium-mult) * var(--square-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--medium-mult) * var(--square-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.landscape-slider.small-slider {
  height: calc(
    var(--small-mult) * var(--landscape-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--small-mult) * var(--landscape-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.portrait-slider.small-slider {
  height: calc(
    var(--small-mult) * var(--portrait-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--small-mult) * var(--portrait-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.square-slider.small-slider {
  height: calc(
    var(--small-mult) * var(--square-height) + calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--small-mult) * var(--square-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.landscape-slider.extra-small-slider {
  height: calc(
    var(--extra-small-mult) * var(--landscape-height) +
      calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--extra-small-mult) * var(--landscape-width) +
      calc(var(--side-margin) * 2)
  );
}

.sliding-image.portrait-slider.extra-small-slider {
  height: calc(
    var(--extra-small-mult) * var(--portrait-height) +
      calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--extra-small-mult) * var(--portrait-width) +
      calc(var(--side-margin) * 2)
  );
}

.sliding-image.square-slider.extra-small-slider {
  height: calc(
    var(--extra-small-mult) * var(--square-height) +
      calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--extra-small-mult) * var(--square-width) + calc(var(--side-margin) * 2)
  );
}

.sliding-image.square-slider.extra-extra-small-slider {
  height: calc(
    var(--extra-extra-small-mult) * var(--square-height) +
      calc(var(--side-margin) * 2)
  );
  width: calc(
    var(--extra-extra-small-mult) * var(--square-width) +
      calc(var(--side-margin) * 2)
  );
}

.sliding-image-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 5;
}

.sliding-image-frame.landscape-slider {
  width: calc(var(--medium-mult) * var(--landscape-width) + 5em);
}

.sliding-image-frame.portrait-slider {
  width: calc(var(--medium-mult) * var(--portrait-width) + 4em);
}

.sliding-image-frame.square-slider {
  width: calc(var(--medium-mult) * var(--square-width) + 5em);
}

.sliding-image-frame.landscape-slider.small-slider {
  width: calc(var(--small-mult) * var(--landscape-width) + 4em);
}

.sliding-image-frame.portrait-slider.small-slider {
  width: calc(var(--small-mult) * var(--portrait-width) + 3em);
}

.sliding-image-frame.square-slider.small-slider {
  width: calc(var(--small-mult) * var(--square-width) + 4em);
}

.sliding-image-frame.landscape-slider.extra-small-slider {
  width: calc(var(--extra-small-mult) * var(--landscape-width) + 3em);
}

.sliding-image-frame.portrait-slider.extra-small-slider {
  width: 11.6em;
  width: calc(var(--extra-small-mult) * var(--portrait-width) + 2.6em);
}

.sliding-image-frame.square-slider.extra-small-slider {
  width: 15em;
  width: calc(var(--extra-small-mult) * var(--square-width) + 3em);
}

.sliding-image-frame.square-slider.extra-extra-small-slider {
  width: 9em;
  width: calc(var(--extra-extra-small-mult) * var(--square-width) + 3em);
}

.image-under-slider {
  position: absolute;
  top: 50%;
}

.image-under-slider.landscape-slider,
.image-slider-container.landscape-slider {
  height: calc(var(--medium-mult) * var(--landscape-height));
  width: calc(var(--medium-mult) * var(--landscape-width));
}

.image-under-slider.portrait-slider,
.image-slider-container.portrait-slider {
  height: calc(var(--medium-mult) * var(--portrait-height));
  width: calc(var(--medium-mult) * var(--portrait-width));
}

.image-under-slider.square-slider,
.image-slider-container.square-slider {
  height: calc(var(--medium-mult) * var(--square-height));
  width: calc(var(--medium-mult) * var(--square-width));
}

.image-under-slider.landscape-slider.small-slider,
.image-slider-container.landscape-slider.small-slider {
  height: calc(var(--small-mult) * var(--landscape-height));
  width: calc(var(--small-mult) * var(--landscape-width));
}

.image-under-slider.portrait-slider.small-slider,
.image-slider-container.portrait-slider.small-slider {
  height: calc(var(--small-mult) * var(--portrait-height));
  width: calc(var(--small-mult) * var(--portrait-width));
}

.image-under-slider.square-slider.small-slider,
.image-slider-container.square-slider.small-slider {
  height: calc(var(--small-mult) * var(--square-height));
  width: calc(var(--small-mult) * var(--square-width));
}

.image-under-slider.landscape-slider.extra-small-slider,
.image-slider-container.landscape-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--landscape-height));
  width: calc(var(--extra-small-mult) * var(--landscape-width));
}

.image-under-slider.portrait-slider.extra-small-slider,
.image-slider-container.portrait-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--portrait-height));
  width: calc(var(--extra-small-mult) * var(--portrait-width));
}

.image-under-slider.square-slider.extra-small-slider,
.image-slider-container.square-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--square-height));
  width: calc(var(--extra-small-mult) * var(--square-width));
}

.image-under-slider.square-slider.extra-extra-small-slider,
.image-slider-container.square-slider.extra-extra-small-slider {
  height: calc(var(--extra-extra-small-mult) * var(--square-height));
  width: calc(var(--extra-extra-small-mult) * var(--square-width));
}

.resizing-wrapper {
  position: absolute;
  overflow: hidden;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 20;
  left: var(--side-margin);
}

.resizing-wrapper.landscape-slider,
.image-slider-thumb.landscape-slider {
  height: calc(var(--medium-mult) * var(--landscape-height));
}

.resizing-wrapper.portrait-slider,
.image-slider-thumb.portrait-slider {
  height: calc(var(--medium-mult) * var(--portrait-height));
}

.resizing-wrapper.square-slider,
.image-slider-thumb.square-slider {
  height: calc(var(--medium-mult) * var(--square-height));
}

.resizing-wrapper.landscape-slider.small-slider,
.image-slider-thumb.landscape-slider.small-slider {
  height: calc(var(--small-mult) * var(--landscape-height));
}

.resizing-wrapper.portrait-slider.small-slider,
.image-slider-thumb.portrait-slider.small-slider {
  height: calc(var(--small-mult) * var(--portrait-height));
}

.resizing-wrapper.square-slider.small-slider,
.image-slider-thumb.square-slider.small-slider {
  height: calc(var(--small-mult) * var(--square-height));
}

.resizing-wrapper.landscape-slider.extra-small-slider,
.image-slider-thumb.landscape-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--landscape-height));
}

.resizing-wrapper.portrait-slider.extra-small-slider,
.image-slider-thumb.portrait-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--portrait-height));
}

.resizing-wrapper.square-slider.extra-small-slider,
.image-slider-thumb.square-slider.extra-small-slider {
  height: calc(var(--extra-small-mult) * var(--square-height));
}

.resizing-wrapper.square-slider.extra-extra-small-slider,
.image-slider-thumb.square-slider.extra-extra-small-slider {
  height: calc(var(--extra-extra-small-mult) * var(--square-height));
}

.original-slider-image {
  z-index: 20;
  left: var(--side-margin);
  -webkit-transform: translate(calc(-1 * var(--side-margin)), -50%);
          transform: translate(calc(-1 * var(--side-margin)), -50%);
}

.original-slider-image.landscape-slider {
  width: calc(var(--regular-mult) * var(--landscape-width));
}

.original-slider-image.portrait-slider {
  width: calc(var(--regular-mult) * var(--portrait-width));
}

.original-slider-image.square-slider {
  width: calc(var(--regular-mult) * var(--square-width));
}

.original-slider-image.landscape-slider.small-slider {
  width: calc(var(--small-mult) * var(--landscape-width));
}

.original-slider-image.portrait-slider.small-slider {
  width: calc(var(--small-mult) * var(--portrait-width));
}

.original-slider-image.square-slider.small-slider {
  width: calc(var(--small-mult) * var(--square-width));
}

.original-slider-image.landscape-slider.extra-small-slider {
  width: calc(var(--extra-small-mult) * var(--landscape-width));
}

.original-slider-image.portrait-slider.extra-small-slider {
  width: calc(var(--extra-small-mult) * var(--portrait-width));
}

.original-slider-image.square-slider.extra-small-slider {
  width: calc(var(--extra-small-mult) * var(--square-width));
}

.original-slider-image.square-slider.extra-extra-small-slider {
  width: calc(var(--extra-extra-small-mult) * var(--square-width));
}

.pixelated-slider-image {
  z-index: 10;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.image-slider-container {
  position: relative;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 30;
}

.image-slider {
  width: 100%;
  z-index: 50;
}

.image-slider-thumb {
  width: 4px;
  text-align: center;
  background-color: #c296a8;
  color: transparent;
  cursor: grab;
}

.slider-handle {
  position: absolute;
  top: 70%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  cursor: grab;
  width: 20px;
}

.slider-handle.landscape-slider {
  left: 47%;
}

.slider-handle.portrait-slider {
  left: 46%;
}

.slider-handle.square-slider {
  left: 46%;
}

.slider-handle.landscape-slider.small-slider {
  left: 46%;
}

.slider-handle.portrait-slider.small-slider {
  left: 45%;
}

.slider-handle.square-slider.small-slider {
  left: 45%;
}

.slider-handle.portrait-slider.extra-small-slider {
  left: 43%;
}

.slider-handle.landscape-slider.extra-small-slider {
  left: 44.5%;
}

.slider-handle.square-slider.extra-small-slider {
  left: 45%;
}

.slider-handle.square-slider.extra-extra-small-slider {
  left: 43%;
}

.mini-gallery {
  display: inline-flex;
  flex-direction: column;
  margin: 1em;
}

.mini-gallery-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.mini-gallery-options.small {
  justify-content: center;
}

.mini-gallery-thumbnail {
  height: 3em;
  margin: 4px;
}

.mini-gallery-thumbnail.small {
  height: 2em;
  margin: 3px;
}

.room-tester {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.test-room {
  width: 100%;
  max-width: 500px;
  margin: 5% 0;
}

img.test-room.wide-room {
  width: 100%;
  min-width: 100%;
  margin: 0;
}

img.test-room.small-room {
  width: 100%;
  max-width: 300px;
}

.test-image-frame {
  position: absolute;
  z-index: 10;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.test-image-frame.LL-frame {
  width: 250px;
  top: 14%;
}

/* outdated */
.test-image-frame.LM-frame {
  width: 340px;
  top: 20%;
}

/* outdated */
.test-image-frame.LS-frame {
  width: 260px;
  top: 20%;
}

.test-image-frame.PL-frame {
  width: 210px;
  top: 10%;
}

/* outdated */
.test-image-frame.PM-frame {
  width: 190px;
  top: 9%;
}

/* outdated */
.test-image-frame.PS-frame {
  width: 190px;
  top: 16%;
}

.test-image-frame.SL-frame {
  width: 250px;
  top: 7%;
}

/* outdated */
.test-image-frame.SM-frame {
  width: 260px;
  top: 12%;
}

/* outdated */
.test-image-frame.SS-frame {
  width: 170px;
  top: 20%;
}

.test-image {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 20;
}

.mini-room-gallery {
  display: flex;
}

.mini-room-gallery.flow-right {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 440px;
}

.mini-room-gallery.flow-down {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 380px;
}

.mini-room-gallery-thumbnails {
  display: flex;
}

.mini-room-gallery-thumbnails.flow-right {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5%;
  flex-wrap: wrap;
}

.mini-room-gallery-thumbnails.flow-down {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -20px;
}

.mini-room-gallery-thumbnail {
  height: 2.2em;
  margin: 0.25em;
}

div.mobile-page {
  margin-top: 55px;
}

div.room-for-blade {
  margin-left: 3em;
}

.price-sidebar {
  width: 100%;
  height: auto;
  padding: 25px;
  padding-bottom: 15px;
  box-sizing: border-box;
  border: 2px solid #efb065;
}

.sidebar-pricing-table {
  display: grid;
  width: 100%;
  grid-template-columns: 60% 40%;
}

.sidebar-pricing-cell {
  padding: 0.25em;
}

.sidebar-pricing-special {
  padding: 0.5em 0.25em;
}

.progress-bar-container {
  position: relative;
  max-width: 16em;
  height: 0.6em;
  margin: 0 auto;
  display: block;
}

.progress-bar-track {
  background-color: #ddd;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.progress-bar-full {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #c296a8;
  border-radius: 10px;
  transition: 0.4s linear;
}

.progress-bar-full.slow-progress-bar {
  transition: 0.6s linear;
}

.progress-bar-full.medium-progress-bar {
  transition: 0.4s linear;
}

.progress-bar-full.fast-progress-bar {
  transition: 0.2s linear;
}

.progress-marker-container {
  width: 9em;
  position: absolute;
  display: inline-block;
  top: 1em;
  font-size: 1em;
}

.progress-marker-container.small {
  width: 6em;
}

.progress-marker-container.small p {
  font-size: 0.9em;
  margin-top: 0.05em;
  margin-left: 0.4em;
}

.progress-marker {
  position: relative;
  width: 100%;
}

.progress-marker-image {
  width: 100%;
}

.progress-marker-text {
  position: absolute;
  left: 20%;
  top: 15%;
  color: #fff;
}

.progress-marker-text.small-marker-text {
  left: 30%;
}

/* for desktops */
div.responsive-page {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 72px;
  overflow-y: auto;
}

@media only screen and (min-width: 769px) {
  div.responsive-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

/* for tablets */
@media only screen and (max-width: 768px) {
  div.responsive-page {
    margin-top: 55px;
  }
}

.selectable-tab {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.4em;
  margin: 0.1em;
  cursor: pointer;
  transition: 0.4s;
  width: 5em;
  background-color: #6d8cc3;
  color: #fff;
}

.selectable-tab:hover {
  background-color: #84a3d9;
}

.selectable-tab.active-tab,
.selectable-tab.active-tab:hover {
  background-color: #efb065;
}

.account-page {
  width: 100%;
  height: 84vh;
  box-sizing: border-box;
  position: relative;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-size: cover;
  background-position: center;
}

.account-page-inner {
  width: 100%;
  height: inherit;
  padding-left: 3em;
  box-sizing: border-box;
  position: relative;
}

.account-content {
  width: 80%;
  max-width: calc(100% - 170px);
  height: 84vh;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.account-subpage-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.account-subpage {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 2em 4em;
  height: 80%;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}

.account-button-area {
  margin-top: auto;
  height: 20%;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 2em;
}

.account-button {
  display: inline-block;
}

.help-button-container {
  float: right;
}

.order-thumbnail {
  width: 90%;
  margin: 0 auto;
}

.item-thumbnail {
  width: 100%;
}

.tracking-cell {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 880px) {
  div.account-subpage {
    padding: 40px;
  }

  .account-welcome {
    font-size: 1.7em;
  }
}

@media only screen and (max-width: 768px) {
  div.account-content {
    height: auto;
    min-height: calc(calc(100vh - 55px) - 80px);
  }

  div.order-thumbnail-cell {
    margin: 8px;
  }

  div.account-content {
    width: 100%;
    max-width: 100%;
  }

  .account-header,
  .account-text {
    text-align: center;
  }

  div.account-subpage {
    padding: 40px 20px 0 20px;
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  div.help-button-container,
  div.back-button-container,
  div.order-again-button {
    float: none;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  div.order-again-button {
    margin-top: 30px;
  }
}

aside.account-sidebar {
  width: 20%;
  min-width: 170px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 15px 20px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}

div.account-desktop-menu {
  margin: 35px 0;
}

p.account-desktop-menu-item {
  margin: 1em;
  transition: 0.4s;
}

p.account-desktop-menu-item:hover {
  color: #4f3b6e;
}

div.account-top-menu {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #6d8cc3;
  position: relative;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  z-index: 1000;
}

div.account-icon-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

img.account-menu-icon {
  margin: 0 8px;
}

.account-menu-welcome {
  margin-left: auto;
}

div.account-top-menu-container {
  position: relative;
}

div.account-menu-dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
}

div.account-dropdown-option {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #efb065;
  position: relative;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  z-index: 100;
}

div.account-dropdown-option.focused {
  background-color: #fccb90;
}

.edit-account {
  padding-bottom: 0;
}

.edit-account-form-container {
  height: 40vh;
  overflow: auto;
  margin: 1em;
}

.edit-account-form {
  width: 70%;
}

.edit-account-button-area {
  width: 80%;
}

.edit-account-button-container {
  float: right;
}

@media only screen and (max-width: 768px) {
  form.edit-account-form {
    width: 100%;
  }

  div.edit-account-form-container {
    height: auto;
  }

  div.edit-account-button-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.order-details-content {
  display: grid;
  width: 70%;
  grid-template-columns: 60% 40%;
}

.order-details-body {
  display: flex;
  flex-direction: row;
  position: relative;
}

.order-detail-table-container {
  max-height: 40vh;
  overflow: auto;
}

.order-detail-table {
  display: grid;
  width: 95%;
  grid-template-columns: 30% 30% 20% 20%;
  margin: 0.5em 0;
}

.order-details-main {
  width: 70%;
  box-sizing: border-box;
  display: inline-block;
}

.order-details-aside {
  margin-top: 40px;
  width: 30%;
  box-sizing: border-box;
  display: inline-block;
}

@media only screen and (max-width: 1000px) {
  p.large-text.order-detail-table-header {
    font-size: 1.1em;
  }

  p.large-text.order-detail-text {
    font-size: 1em;
  }

  p.large-text.item-detail-size {
    font-size: 1em;
  }

  div.account-subpage.order-details {
    padding: 30px;
  }
}

@media only screen and (max-width: 880px) {
  div.order-details-body {
    flex-wrap: wrap;
    margin-top: 200px;
  }

  div.order-details-main {
    width: 100%;
  }

  aside.order-details-aside {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  div.order-details-body {
    margin-top: 0;
  }
}

@media only screen and (max-width: 600px) {
  div.order-details-content {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 500px) {
  div.order-details-content {
    width: 90%;
  }

  div.order-detail-table {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

.order-history-content-container {
  height: auto;
  overflow: auto;
}

.order-history-content {
  display: grid;
  width: 70%;
  grid-template-columns: 20% 50% 30%;
}

@media only screen and (min-width: 1000px) {
  div.order-history-content-container {
    max-height: 60vh;
  }
}

@media only screen and (max-width: 1100px) {
  div.order-history-content {
    width: 85%;
  }
}

@media only screen and (max-width: 880px) {
  div.order-history-content {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  div.no-orders {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  p.large-text.order-history-header {
    font-size: 1.1em;
  }

  p.large-text.order-history-text {
    font-size: 1em;
  }

  div.order-history-content {
    grid-template-columns: 30% 33% 37%;
  }
}

.reorder-table {
  display: grid;
  width: 90%;
  grid-template-columns: 20% 40% 25% 15%;
  margin: 0.5em 0;
}

.reorder-content {
  margin: 2em 0;
}

.reorder-table-container {
  height: 30vh;
  width: 100%;
  overflow: auto;
}

.reorder-button-section {
  width: 100%;
  box-sizing: border-box;
  padding: 0 2em;
  height: 5em;
}

.reorder-button-wrapper {
  float: right;
}

@media only screen and (max-width: 768px) {
  div.reorder-table-container {
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  div.reorder-button-section {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  div.reorder-content {
    margin-bottom: 0;
  }

  p.large-text.reorder-table-header {
    font-size: 1.1em;
  }

  p.large-text.reorder-table-text {
    font-size: 1em;
  }

  div.reorder-table {
    grid-template-columns: 20% 25% 30% 25%;
  }

  div.reorder-thumbnail {
    margin: 5px;
  }
}

.slider-page {
  width: 100%;
  height: calc(calc(100vh - 72px) - 42px);
  box-sizing: border-box;
  display: flex;
  overflow: auto;
}

.orders-page,
.coupons-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.orders-container,
.coupons-container {
  height: auto;
  width: 100%;
  position: relative;
  padding: 15px;
  box-sizing: border-box;
}

.admin-header {
  margin: 2em;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.view-selector {
  position: absolute;
  top: 70px;
  left: 30px;
}

div.admin-order-header,
div.admin-order-table {
  position: relative;
  display: grid;
  grid-template-columns: 8em 6em 9em 10em 10em 10em 9em 9em 8em;
  /* grid-template-columns: minmax(8em, 25%) minmax(6em, 25%) 9em minmax(10em, 25%) 10em 10em minmax(8em, 25%); */
  width: 90%;
  box-sizing: border-box;
  margin: 0 auto;
}

div.admin-order-table {
  min-height: 50%;
}

.admin-coupons-header,
.admin-coupons-table {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 12em 8em;
  width: 90%;
  box-sizing: border-box;
  margin: 0 auto;
}

.admin-coupons-table {
  max-height: 50vh;
  overflow: auto;
}

.admin-expand {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.center-align-col > div > .admin-expand {
  margin-left: 20%;
  justify-content: center;
}

.admin-expand-icon {
  height: 6px;
  margin: 0 10px;
}

.pdf-section {
  transition: 0.4s;
  overflow: hidden;
}

.pdf-section.expanded {
  max-height: 300px;
}

.collapsed {
  max-height: 0;
}

.tracking-editor,
.coupon-editor {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.finish-icon-section {
  display: flex;
  flex-direction: row;
}

.admin-edit-icon {
  height: 12px;
  margin: 0 10px;
  cursor: pointer;
}

.admin-finish-icon {
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
}

.admin-finish-icon.big-finish-icon {
  height: 20px;
}

.new-coupon-button {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

@media only screen and (max-width: 1200px) {
  div.admin-order-header,
  div.admin-order-table {
    width: 100%;
  }

  div.admin-order-header > div > p {
    font-size: 1em;
  }
}

@media only screen and (max-width: 880px) {
  div.view-selector {
    top: 20px;
  }

  div.admin-header {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 768px) {
  div.admin-order-header,
  div.admin-order-table {
    grid-template-columns: 1.5fr 1fr 2fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  div.admin-order-header > div > p,
  div.admin-order-table {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 400px) {
  div.view-selector {
    left: 5px;
  }
}

.cart-page {
  position: relative;
  height: 84vh;
  box-sizing: border-box;
  width: 100%;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.shopping-cart {
  text-align: center;
  width: 100%;
  height: 74vh;
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.empty-cart-message {
  min-width: 50%;
  max-width: 30em;
}

.cart-item-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
  max-height: 400px;
  position: relative;
}

.cart-heading-container {
  width: 100%;
}

.cart-heading {
  display: grid;
  width: 90%;
  margin: 1% 0;
  grid-template-columns: 25% 35% 20% 20%;
}

.cart-grid-container {
  position: relative;
  max-height: 52vh;
  overflow-y: auto;
  width: 100%;
}

.cart-grid {
  display: grid;
  width: 90%;
  max-height: inherit;
  margin: 1% 0;
  grid-template-columns: 25% 35% 20% 20%;
}

.cart-total-grid-container {
  width: 100%;
}

.cart-total-grid {
  display: grid;
  width: 90%;
  height: 4vh;
  margin-top: 5%;
  grid-template-columns: 80% 20%;
  box-sizing: border-box;
}

.grid-cell {
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart-item-container {
  margin: 2em;
  box-sizing: border-box;
  width: 100%;
}

.cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1em;
  padding: 1em 2em;
  border: 1px solid grey;
}

.cart-display-photo {
  width: 40%;
  margin: 5% auto;
}

.brix-text-container {
  text-align: left;
}

.continue-shopping-section {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 1em;
  height: 3em;
  box-sizing: border-box;
  margin-top: auto;
}

.to-precheckout-button {
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  div.cart-item-section {
    padding: 2% 0;
  }

  div.cart-heading,
  div.cart-grid {
    width: 100%;
  }

  img.cart-display-photo {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  div.cart-heading > div > p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  div.cart-heading,
  div.cart-grid {
    grid-template-columns: 25% 25% 30% 20%;
  }

  p.large-text.brix-size {
    font-size: 1rem;
  }
}

.checkout-container {
  position: relative;
  display: block;
  width: 100%;
  height: 84vh;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: bottom center;
  background-size: cover;
  box-sizing: border-box;
  margin-bottom: auto;
}

.pre-checkout {
  text-align: center;
  width: 100%;
  position: relative;
  padding: 3%;
  box-sizing: border-box;
}

.pre-checkout-options {
  width: 90%;
  height: auto;
  margin: 1% auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.checkout-option-box {
  position: relative;
  width: 50%;
  min-width: 400px;
  background-color: rgba(255, 255, 255, 0.3);
  height: auto;
  border: 1px solid #6d8cc3;
  padding: 3%;
  margin: 5%;
  box-sizing: border-box;
}

.checkout-option-content {
  margin: 10% 2%;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.user-checkout-form {
  text-align: left;
  width: 90%;
  overflow: auto;
}

.checkout-login-note {
  margin-top: 1em;
}

.checkout {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 4em;
}

.checkout-content {
  display: block;
  width: calc(100% - 250px);
}

.checkout-inner {
  width: 100%;
  height: 90%;
  overflow-y: auto;
}

.shipping-section {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  z-index: 1;
}

.shipping-content {
  margin-top: 1em;
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
}

.saved-address-section {
  margin: 0 1em;
  max-width: 50%;
  display: inline-block;
  box-sizing: border-box;
}

.saved-address-section.full-width {
  max-width: 100%;
}

.saved-addresses {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.address-option {
  display: block;
  padding: 1em 2em;
  margin: 0.5em;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #efb065;
}

.no-addresses {
  margin: 1em 0;
}

.new-address-section {
  margin: 0 1em;
  display: inline-block;
  width: 40%;
  box-sizing: border-box;
}

.address-container {
  display: inline-block;
}

.address-link {
  margin: 0.5em 1em;
}

.address-form-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.address-form-footer-button-container {
  margin-left: auto;
}

.shipping-button-container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.shipping-button {
  float: right;
}

.review-section-content {
  margin-bottom: 1em;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
}

.shipping-final-form {
  margin: 1em;
  display: flex;
  flex-direction: row;
}

.shipping-final-form-left {
  display: flex;
  flex-direction: column;
}

.shipping-radio-section {
  margin: 0 2em;
}

.review-button-container {
  float: right;
}

.payment-section {
  margin: 1em 0;
}

.payment-form-container {
  box-sizing: border-box;
  margin: 1em 2em;
  margin-right: 0;
}

.card-element {
  margin-bottom: 1em;
  min-width: 300px;
  max-width: 100%;
}

.stripe-button {
  background-color: #5f4158;
  padding: 0.8em 1.7em;
  font-size: 1em;
  margin: 1em;
  transition: 0.3s;
  color: #fff;
  font-family: 'Avenir Light', sans-serif;
  cursor: pointer;
  float: right;
}

.stripe-button:hover {
  background-color: #7a5472;
}

.checkout-side-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2em;
  min-width: 250px;
  height: auto;
}

.add-coupon-section {
  position: relative;
  margin-top: 5px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border: 2px solid #c296a8;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.coupon-button {
  position: relative;
  margin-left: auto;
}

.checkout-success-section {
  margin: 2em 0;
}

@media only screen and (max-width: 1000px) {
  .shipping-content,
  .shipping-final-form {
    flex-direction: column;
  }

  .saved-address-section {
    max-width: none;
    width: 100%;
  }

  .new-address-section {
    width: 90%;
  }

  .shipping-radio-section {
    margin: 0;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  div.checkout {
    flex-wrap: wrap;
  }

  div.checkout-content {
    width: 100%;
  }

  aside.checkout-side-section {
    margin: 30px auto 0 auto;
    position: relative;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  div.checkout {
    padding: 20px;
  }

  .payment-form-container {
    margin-left: 0;
  }

  .card-element {
    min-width: 200px;
  }
}

@media only screen and (max-width: 400px) {
  .checkout-option-box {
    min-width: 300px;
  }
}

.contact {
  position: relative;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.contact-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 3em);
  height: 100%;
  margin-left: 3em;
}

.contact-notes {
  max-width: 30%;
  min-width: 300px;
  height: 100%;
  padding: 3em;
  padding-top: 7em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contact-main {
  width: 60%;
  height: 100%;
  box-sizing: border-box;
  padding: 2em 3em;
}

.contact-form {
  margin: 1em 1.5em;
  min-width: 70%;
  max-width: 300px;
  height: 380px;
  overflow-y: auto;
  box-sizing: border-box;
}

.contact-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.contact-success {
  width: 80%;
  margin: 50px auto;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  div.contact-content {
    flex-wrap: wrap;
  }

  div.contact-notes {
    min-width: 90%;
    margin: 0 auto;
    padding: 40px 20px 0 20px;
    text-align: center;
    justify-content: flex-start;
    height: auto;
  }

  div.contact-notes-main {
    padding-bottom: 20px;
  }

  div.contact-main {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  form.contact-form {
    margin: 0 auto;
    min-width: 90%;
  }
}

.faq {
  padding: 3em 4em;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.faq-bullets {
  margin-left: 4em;
}

.home-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1608657973/brixpix-assets/backgrounds/HomePage_-_WebBackGrounds_mgepn7.jpg');
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: nowrap;
  position: relative;
  overflow-y: auto;
  align-items: flex-start;
}

.home-page-inner {
  height: inherit;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .home-page {
    min-height: calc(100vh - 55px);
    height: auto;
  }
}

/* large devices */
@media only screen and (min-width: 769px) {
  div.home-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

div.homepage-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 10px;
}

.homepage-text {
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  font-size: 30px;
}

.start-button {
  padding: 5px;
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

div.home-image-section {
  display: grid;
  width: 80%;
  margin: 0 auto;
  position: relative;
}

div.home-image-section.desktop-home-image-section {
  grid-template-columns: 28% 5% 33% 5% 28%;
}

div.home-image-section.mobile-home-image-section {
  grid-template-columns: 45% 55%;
  grid-template-rows: 150px 250px 150px;
  margin: 20px 0;
  width: 100%;
}

.home-fade-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .home-fade-container {
    padding: 0 10px;
    box-sizing: border-box;
  }
}

.home-fade-container.left-section {
  justify-content: flex-end;
}

.home-fade-container.center-section {
  justify-content: center;
}

.home-fade-container.right-section {
  justify-content: flex-start;
}

.home-fade-container.top-section {
  padding-bottom: 10%;
}

.home-fade-container.bottom-section {
  padding-top: 10%;
}

.home-fade-container :not(.home-arrow) {
  z-index: 10;
}

.home-arrow.down-right-arrow {
  position: absolute;
  left: 45%;
  top: 17%;
  width: 70px;
}

.home-arrow.down-left-arrow {
  position: absolute;
  left: 45%;
  top: 70%;
  width: 70px;
}

@media only screen and (max-width: 500px) {
  .home-arrow.down-right-arrow {
    top: 18%;
  }

  .home-arrow.down-left-arrow {
    top: 65%;
  }
}

img.brix-pieces {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 250px;
}

@media only screen and (max-width: 1000px) {
  img.brix-pieces {
    max-height: 180px;
  }
}

@media only screen and (max-width: 768px) {
  img.brix-pieces {
    position: absolute;
    max-width: 110%;
    max-height: 280px;
  }
}

@media only screen and (max-width: 500px) {
  img.brix-pieces {
    max-height: 220px;
  }
}

.home-banner {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #6d8cc3b0;
  padding: 5px;
  margin: 20px 10px 10px 10px;
  margin-left: auto;
  margin-right: auto;
}

h3.homepage-end {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1200px) {
  div.home-page > h3 {
    margin-left: auto;
    text-align: right;
  }

  div.homepage-copy > div > p {
    font-size: 1.2em;
  }

  div.homepage-copy > div > h2 {
    font-size: 2em;
  }
}

@media only screen and (max-width: 1000px) {
  div.home-page > h3 {
    font-size: 1.6em;
  }

  div.homepage-copy > div > p {
    font-size: 1em;
  }

  div.homepage-copy > div > h2 {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 768px) {
  div.main-slider-page {
    padding: 20px 0;
  }

  h3.homepage-end {
    padding: 0 30px;
  }

  div.homepage-copy {
    flex-wrap: wrap;
    padding: 0 20px;
  }

  div.homepage-top {
    grid-template-columns: 1fr;
  }
}

.about-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1608657974/brixpix-assets/backgrounds/What_is_Brix_-_WebBackGrounds_ggdiup.jpg');
  background-position: center;
  background-size: cover;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
}

div.about-page-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  box-sizing: border-box;
}

@media only screen and (min-width: 769px) {
  div.about-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

.about-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-slider-container {
  padding: 0 3% 0 1%;
}

.about-copy-container {
  padding: 2% 3%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.about-end {
  margin: 30px 0;
}

@media only screen and (max-width: 1200px) {
  h4.about-end {
    font-size: 1.2em;
    margin: 20px 0;
  }

  div.about-copy-container > div > h2 {
    font-size: 1.8em;
  }
}

@media only screen and (max-width: 1000px) {
  div.about-copy-container > div > p {
    font-size: 1em;
  }

  div.about-page {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 880px) {
  div.about-slider-container {
    padding: 0;
  }

  div.about-copy-container > div > h2 {
    font-size: 1.6em;
  }
}

@media only screen and (max-width: 768px) {
  div.about-page,
  div.about-page-inner {
    flex-wrap: wrap;
    text-align: center;
  }

  div.about-left,
  div.about-copy-container {
    width: 100%;
  }
}

.pricing-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1608670753/brixpix-assets/backgrounds/Pricing_Page_-_WebBackGrounds_tz9tco.jpg');
  background-position: center;
  background-size: cover;
  overflow-y: auto;
}

@media only screen and (min-width: 769px) {
  div.pricing-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

.pricing-inner {
  height: inherit;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.pricing-section {
  text-align: center;
  max-width: 70%;
  min-width: 90px;
  overflow-y: auto;
  box-sizing: border-box;
}

.pricing-table-container {
  overflow-y: scroll;
}

.pricing-table-header {
  display: grid;
  grid-template-columns: 40% 30% 30%;
}

.pricing-table {
  display: grid;
  grid-template-columns: 40% 30% 30%;
}

.pricing-inner {
  padding: 5px 15px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1200px) {
  div.pricing-section > h1 {
    font-size: 1.8em;
  }
}

@media only screen and (max-width: 1000px) {
  div.pricing-right {
    padding: 10px;
  }
}

@media only screen and (max-width: 768px) {
  div.pricing-inner {
    flex-wrap: wrap;
  }

  div.pricing-right {
    text-align: center;
  }
}

.gallery-page {
  overflow: hidden;
  box-sizing: border-box;
}

.gallery-display {
  max-height: 72vh; /* TODO: change this */
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (min-width: 769px) {
  div.gallery-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

.gallery-controls-container {
  position: absolute;
  width: 100%;
  height: 80px;
  bottom: 0;
}

.gallery-controls {
  background-color: #6d8cc3;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 400px 1fr 1fr;
}

.gallery-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-options {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-options-header {
  align-items: center;
}

.gallery-options-radio {
  align-items: flex-start;
  flex-wrap: wrap;
}

div.gallery-no-controls,
div.gallery-background {
  height: calc(calc(100vh - 72px) - 42px);
  width: 100%;
}

div.gallery-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 768px) {
  div.gallery-page {
    padding: 0;
  }

  div.gallery-no-controls,
  div.gallery-background {
    height: calc(calc(100vh - 55px) - 80px);
  }
}

.order-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1608657973/brixpix-assets/backgrounds/Start_Order_Page_-_WebBackGrounds_rnnobw.jpg');
  background-position: center;
  background-size: cover;
  padding: 15px;
  width: 80%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: auto;
}

@media only screen and (min-width: 769px) {
  div.order-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

.order-page-content {
  position: relative;
  box-sizing: border-box;
  margin: 0 100px;
  display: inline-flex;
  flex-direction: column;
}

.order-start-container {
  float: right;
}

.do-this-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.do-this-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 260px;
  box-sizing: border-box;
}

.do-this-pic {
  width: 200px;
}

@media only screen and (max-width: 1000px) {
  .order-page-content {
    margin: 0 50px;
  }
}

@media only screen and (max-width: 768px) {
  div.do-this-section {
    flex-direction: column;
    align-items: center;
  }

  div.order-page-content {
    text-align: center;
  }

  div.order-inner-content {
    font-size: 0.8em;
    text-align: center;
  }

  div.order-start-container {
    float: none;
    margin: 15px 0;
  }
}

div.main-page {
  --blade-width: 3em;
  --num-blades: 5;

  position: relative;
  display: block;
  width: 100%;
  overflow-y: auto;
}

div.main-slider-page {
  width: 100%;
}

.privacy-policy {
  padding: 3em 4em;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.privacy-policy-bullets {
  margin-left: 4em;
}

.terms-and-conditions {
  padding: 3em 4em;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.login-container {
  position: relative;
  box-sizing: border-box;
  margin-left: 3em;
  width: calc(100vw - 3em);
  height: 100%;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
  overflow-y: auto;
}

.login-form-container {
  position: relative;
  box-sizing: border-box;
  width: 50%;
  height: auto;
  margin: 0 15% 0 35%;
  padding: 50px 30px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.login-form {
  margin: 1em 2em;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.login-button-container {
  margin: 0 auto;
}

.login-padded {
  padding: 1em 0;
}

.login-alert-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.tiny-alert {
  width: 1em;
  margin: 0 0.5em;
  margin-bottom: 0.3em;
}

@media only screen and (max-width: 880px) {
  div.login-form-container {
    width: 70%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  div.login-form-container {
    width: 90%;
    margin: 0 auto;
  }

  h1.login-title,
  p.login-subtitle,
  div.login-bottom,
  p.login-bottom {
    text-align: center;
    margin: 10px 0;
  }

  div.login-bottom {
    margin: 0 auto;
  }

  div.login-padded {
    padding: 0;
  }
}

.not-found {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 84vh;
  position: relative;
}

.not-found-content {
  margin: 0 auto;
  padding-top: 5em;
  padding-left: 20%;
  width: 50%;
}

.not-found-copy {
  padding-left: 6em;
}

.not-found-text {
  margin: 0.75em 0;
}

.alert-404-container {
  display: inline-block;
  margin: 0.5em;
}

.alert-404 {
  width: 5em;
}

.alert-404-headings {
  display: inline-block;
}

@media only screen and (max-width: 1000px) {
  div.not-found-content {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  div.not-found-content {
    width: 90%;
    padding-left: 5%;
  }
}

@media only screen and (max-width: 600px) {
  div.not-found-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    height: 100%;
  }

  div.not-found-copy,
  div.alert-404-headings {
    padding: 0;
    text-align: center;
  }

  img.alert-404,
  div.alert-404-container {
    display: block;
    margin: 10px auto;
  }
}

.customize-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* height: 70%; */
}

.customize-heading {
  text-align: center;
}

.visible-customization-container {
  width: 100%;
  max-height: 70vh;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
}

.pixelated-container {
  width: 55%;
  /*originally 40%*/
  height: 80%;
  display: block;
  padding-top: 1.5em;
}

.customizable-image {
  display: block;
  margin: 0 auto;
}

.sliders-container {
  width: 100%;
  height: 46vh;
}

.slider-inner-container {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sliders {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.revert-area {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.revert-button {
  cursor: pointer;
  color: #36284c;
  font-size: 1.5em;
  transition: 0.4s;
}

.revert-button:hover {
  color: #111;
}

.revert-icon {
  height: 1.3em;
}

.edit-blurb {
  width: 90%;
  margin: 0 10px;
  text-align: center;
}

.edit-blurb-heading {
  text-align: center;
  font-weight: bold;
}

.customization-right {
  width: 45%;
  /*originally 50%*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customization-left {
  display: flex;
  flex-direction: column;
}

.advanced-settings-label {
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .visible-customization-container {
    flex-wrap: wrap;
    max-height: none;
  }

  .customization-right {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .pixelated-container {
    width: 100%;
  }

  .customize-header {
    font-size: 28px;
    text-align: center;
    padding: 0 20px;
  }

  .customizable-image {
    margin: 0 auto 20px auto;
  }

  .slider-label-container,
  .slider {
    width: 80% !important;
  }

  .loading-section {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 600px) {
  .visible-customization-container {
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: center;
  }
}

.loading-section {
  margin-top: 30px;
  text-align: center;
}
.review-step {
  width: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-header {
  position: relative;
}

.review-content-container {
  position: relative;
  width: 90%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.review-image-container {
  width: 40%;
  max-height: 60vh;
  margin: 2%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.review-image-wrapper {
  margin: 5%;
  position: relative;
  box-sizing: border-box;
}

.review-image.landscape-review-image {
  width: 250px;
  height: auto;
}

.review-image.portrait-review-image {
  height: 250px;
  width: auto;
}

.review-preview-section {
  min-width: 50%;
  width: auto;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

.review-preview-content {
  width: 90%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
}

.vertical-image-selector {
  width: 30%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  position: relative;
  padding: 0 10% 0 2%;
  margin-top: 5%;
}

.image-thumbnail-placeholder {
  width: 100%;
  height: 2em;
  background-color: green;
  margin: 0.5em 0;
}

.brix-review-info-container {
  margin-top: 0.5em;
}

.brix-review-size-container {
  width: 100%;
  height: 1.3em;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media only screen and (max-width: 768px) {
  .review-step {
    max-height: none;
  }

  .review-header {
    font-size: 28px;
    text-align: center;
    padding: 0 20px;
  }

  .review-content-container {
    flex-wrap: wrap;
  }

  .review-image-container,
  .review-preview-section {
    width: 100%;
    max-height: none;
  }

  .review-preview-section {
    align-items: center;
    margin-bottom: 30px;
  }

  .review-preview-content {
    margin-top: 20px;
  }

  .review-image-wrapper {
    margin: 1%;
    width: 100%;
  }

  .review-image.portrait-review-image,
  .review-image.landscape-review-image {
    width: 80%;
    height: auto;
  }
}

.shape-step {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shape-option-container {
  width: 70%;
}

.shape-option {
  display: grid;
  grid-template-columns: 40% 20% 40%;
  width: 100%;
  margin: 0;
  position: relative;
}

.shape-option-button {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.example-shape-container {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.example-shape {
  background-color: #5f4158;
  box-shadow: 10px 10px 30px 3px #6d8cc3;
  margin: 1em;
}

.shape-description-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

@media only screen and (max-width: 1000px) {
  .shape-option-container {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .shape-option-button {
    min-width: 180px;
  }
}

@media only screen and (max-width: 600px) {
  .shape-option {
    grid-template-columns: 60% 40%;
  }

  .shape-description-container {
    display: none;
  }

  .shape-header {
    font-size: 28px;
    text-align: center;
  }

  .shape-option-container {
    width: 100%;
  }
}

.size-step {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.size-display {
  width: auto;
  padding: 0 2em;
  padding-top: 2em;
  height: 20em;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.size-preview-container {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 3em;
}

.image-placeholder {
  width: 20em;
  height: 12em;
  background-color: blue;
  margin: 5% 0;
}

.size-container {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.size-option-container {
  min-width: 342px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .size-option-container {
    min-width: 230px;
  }
}

@media only screen and (max-width: 600px) {
  .size-header {
    font-size: 28px;
    text-align: center;
  }

  .size-subheader {
    text-align: center;
    padding: 0 30px;
  }

  .size-display {
    padding-top: 0;
    flex-wrap: wrap;
    height: auto;
  }

  .size-option-container {
    margin: 20px auto;
  }

  .unit-container {
    margin: 20px auto;
  }

  .size-preview-container {
    margin: 0 auto;
  }
}

.upload-recommendation-box {
  margin: 2em;
}

.upload {
  width: auto;
  margin: 0 auto;
  text-align: center;
}

.upload-image-container {
  height: 45vh;
  position: relative;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#original-image {
  max-height: 100% !important;
}

.upload-button-container {
  min-width: 6em;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .upload-header {
    font-size: 28px;
    text-align: center;
  }

  .upload-subheader {
    padding: 15px 30px 0 30px;
    text-align: center;
  }

  .upload-text.large-text {
    font-size: 1em;
  }

  .upload-button-container {
    min-width: 6em;
    margin: 20px auto;
  }
}

.step-header {
  position: relative;
  min-height: 80px;
  width: 35em;
  margin: 0 auto;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.step-header.small {
  width: 370px;
}

@media only screen and (max-width: 380px) {
  .step-header {
    margin-left: -10px;
  }
}

.order-process-page {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
  overflow-y: auto;
}

.step-selector-section {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: auto 1em 1.5em 1em;
  height: 3em;
}

.prev-step-button {
  margin-right: auto;
}

.next-step-button {
  margin-left: auto;
}

.password-container {
  position: relative;
  margin-left: 3em;
  width: calc(100vw - 3em);
  min-height: calc(calc(100vh - 55px) - 80px);
  height: 100%;
  overflow-y: auto;
}

.password-form-container {
  position: relative;
  box-sizing: border-box;
  width: 50%;
  height: auto;
  margin: 50px 15% 50px 35%;
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.password-form {
  margin: 1em;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
}

.password-button-container {
  margin-left: auto;
  margin-top: 1em;
}

.password-padded {
  padding: 1em 0;
}

@media only screen and (max-width: 1000px) {
  div.password-form-container {
    width: 50%;
    margin: 50px auto;
  }

  form.password-form {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  div.password-form-container {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  div.password-form-container {
    width: 90%;
  }

  h1.password-title,
  p.password-subtitle,
  .password-text {
    text-align: center;
  }
}

.reset-error {
  position: relative;
  width: 100%;
  min-height: calc(calc(100vh - 55px) - 80px);
  height: 100%;
  overflow-y: auto;
}

.reset-error-container {
  width: 50%;
  padding: 20px;
  margin: auto 15% auto 35%;
  display: flex;
  flex-direction: row;
}

.reset-error-text {
  display: flex;
  flex-direction: column;
}

.reset-error-button-container {
  margin: 1em 0 0 auto;
}

@media only screen and (max-width: 1000px) {
  div.reset-error-container {
    width: 70%;
    margin: auto 10% auto 20%;
  }
}

@media only screen and (max-width: 768px) {
  div.reset-error-container {
    width: 90%;
    margin: auto;
    padding-left: 0;
  }

  h2.reset-error-title {
    font-size: 2em;
  }
}

.signup-container {
  position: relative;
  margin-left: 3em;
  width: calc(100vw - 3em);
  height: 100%;
  overflow-y: auto;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.signup-form-container {
  position: relative;
  box-sizing: border-box;
  width: 50%;
  height: auto;
  margin: 30px 15% 30px 35%;
  padding: 0 2em;
}

@media screen and (max-width: 800px) {
  .signup-form-container {
    margin: 0 auto;
    width: 70%;
  }
}

@media screen and (max-width: 500px) {
  .signup-form-container {
    margin: 0 auto;
    width: 90%;
  }

  .input-line.multi-field {
    flex-wrap: wrap;
  }
}

.signup-form {
  margin: 1em 2em;
  position: relative;
}

.input-line {
  display: flex;
  flex-direction: row;
}

.signup-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  div.signup-form-container {
    width: 60%;
    margin: 30px 15% 30px 25%;
  }
}

@media only screen and (max-width: 1000px) {
  div.signup-form-container {
    width: 70%;
    margin: 30px auto;
  }
}

@media only screen and (max-width: 768px) {
  div.signup-form-container {
    width: 90%;
    margin: 30px auto;
  }
}

@media only screen and (max-width: 600px) {
  h1.signup-title {
    font-size: 2.6em;
    line-height: 1em;
  }

  h1.signup-title,
  p.signup-subtitle {
    text-align: center;
    margin: 10px 0;
  }

  p.signup-bottom {
    text-align: center;
  }
}

input,
label {
  font-family: 'Avenir Light', sans-serif;
}

.text-input-container {
  margin: 0.4em;
  margin-bottom: 0;
  width: 100%;
  position: relative;
}

label {
  display: block;
  font-size: 0.9em;
  margin: 0;
  margin-top: 0.5em;
  color: #3578bd;
}

.text-input {
  outline: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #3578bd;
  padding: 0.2em;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.form-note {
  margin: 0 0.5em;
}

.text-input ~ .text-input-focus-border {
  position: absolute;
  top: 3em;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #3578bd;
  transition: 0.4s;
}

.text-input:focus ~ .text-input-focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}

.text-input ~ .text-input-focus-border.no-label-focus {
  top: 1.4em;
}

.text-input ~ .text-input-focus-border.short-focus {
  top: 2.6em;
}

.input-icon-container {
  width: 2em;
  display: flex;
  align-items: flex-end;
}

.input-icon {
  width: 2em;
  margin-bottom: 0.5em;
}

.radio-button-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.radio-content-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.radio-button {
  position: relative;
  margin: 1.3em;
}

.radio-button.cramped-radio-button {
  margin: 0 1.3em;
}

.outer-radio-button {
  position: relative;
  width: 1.8em;
  height: 1.8em;
  border-radius: 100%;
  border: 7px solid #efb065;
  box-sizing: border-box;
  background-color: transparent;
}

.inner-radio-button {
  width: 0.7em;
  height: 0.7em;
  border-radius: 100%;
  position: absolute;
  left: 0.55em;
  background-color: #efb065;
  top: 0.55em;
}

.radio-button-container.tiny-radio-button
  > .radio-content-container
  > .radio-button {
  margin: 0.1em 0.3em;
}

.radio-button-container.tiny-radio-button
  > .radio-content-container
  > .radio-button
  > .outer-radio-button {
  width: 0.7em;
  height: 0.7em;
  border: 1px solid #fff;
}

.radio-button-container.tiny-radio-button
  > .radio-content-container
  > .radio-button
  > .inner-radio-button {
  left: 2.5px;
  top: 2.5px;
  width: 0.4em;
  height: 0.4em;
  background-color: #fff;
}

.radio-button-sublabel {
  margin-left: 3.6em;
}

.radio-button-sublabel.indented-sublabel {
  margin-left: 4.4em;
}

.radio-button-container.inline-radio-button {
  display: inline-flex;
}

.radio-button-container.inline-radio-button
  > .radio-content-container
  > .radio-button,
.radio-button-container.small-radio-button
  > .radio-content-container
  > .radio-button {
  margin: 0.7em;
}

.radio-button-container.inline-radio-button
  > .radio-content-container
  > .radio-button
  > .outer-radio-button,
.radio-button-container.small-radio-button
  > .radio-content-container
  > .radio-button
  > .outer-radio-button {
  width: 1.2em;
  height: 1.2em;
  border: 4px solid #6d8cc3;
}

.radio-button-container.inline-radio-button
  > .radio-content-container
  > .radio-button
  > .inner-radio-button,
.radio-button-container.small-radio-button
  > .radio-content-container
  > .radio-button
  > .inner-radio-button {
  width: 0.4em;
  height: 0.45em;
  background-color: #6d8cc3;
  left: 0.41em;
  top: 0.35em;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.checkbox {
  margin: 1em;
  position: relative;
}

.outer-checkbox {
  height: 1.1em;
  width: 1.1em;
  background-color: transparent;
  border: 2px solid #6d8cc3;
  box-sizing: border-box;
}

.inner-checkbox {
  position: absolute;
  top: 0.27em;
  left: 0.25em;
  height: 0.65em;
  width: 0.6em;
  z-index: 100;
  background-color: #6d8cc3;
}

.checkbox-main-label {
  margin-top: 1.5em;
}

input.quantity-input {
  border: 1px solid #36284c;
  display: inline-block;
  padding: 3% 5%;
  text-align: right;
  width: 2em;
  font-size: 1.3em;
  margin: 5% 2%;
}

.select-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.5em;
  position: relative;
}

.select-wrapper {
  height: auto;
  width: auto;
  position: relative;
}

select.select-input {
  border: 1px solid #6d8cc3;
  padding: 0.5em 1em;
  padding-right: 2em;
  color: #555;
  font-family: 'Avenir Light', sans-serif;
  transition: 0.4s;
  width: auto;
  position: relative;
  z-index: 1;
}

select.select-input:focus {
  border: 2px solid #6d8cc3;
}

.text-area-container {
  margin: 1em 0.4em;
}

textarea.text-area {
  outline: none;
  resize: none;
  overflow: auto;
  width: 100%;
  height: 5em;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #6d8cc3;
  margin-top: 0.5em;
  font-family: 'Avenir Light', sans-serif;
  color: #555;
}

textarea.text-area:focus {
  border: 2px solid #6d8cc3;
}

.error-message {
  color: #de0235;
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  text-align: left;
  width: 100%;
}

.error-message.large-error {
  font-size: 1.2em;
  text-align: center;
  padding: 0.4em 0.2em;
}

/* FONTS */

/* GENERAL */

html,
body,
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  position: relative;
}

.App {
  position: relative;

  --mobile-width: 600px;
  --tablet-width: 768px;
  --small-desktop-width: 1000px;
  --medium-desktop-width: 1200px;
}

/* DEFAULT TAGS */

p b {
  font-family: 'Avenir', sans-serif;
}

p,
label,
div {
  font-family: 'Avenir Light', sans-serif;
}

a {
  text-decoration: none;
  transition: 0.3s;
  color: #c296a8;
}

h1,
h2 {
  font-family: 'Sanchez', serif;
  color: #36284c;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-family: 'Sanchez', serif;
  color: #6d8cc3;
  font-size: 2em;
}

h4 {
  font-family: 'Sanchez', serif;
  color: #6d8cc3;
  font-size: 1.6em;
}

h5 {
  font-family: 'Sanchez', serif;
  color: #6d8cc3;
  font-size: 1.2em;
}

h6 {
  font-family: 'Sanchez', serif;
  color: #6d8cc3;
  font-size: 0.9em;
}

p.extra-large-text {
  font-size: 2.5em;
  letter-spacing: 0.4px;
}

p.large-text {
  font-size: 1.3em;
  letter-spacing: 0.2px;
  line-height: 1.2em;
}

p.smallish,
li.smallish {
  font-size: 0.8em;
}

p.small,
li.small {
  font-size: 0.6em;
}

p.spacey-text {
  line-height: 2em;
}

/* COLORS */

.purple {
  color: #36284c;
}

.blue {
  color: #6d8cc3;
}

.eggplant {
  color: #5f4158;
}

.rose {
  color: #c296a8;
}

.gold {
  color: #efb065;
}

.dark-grey {
  color: #555;
}

.white {
  color: #fff;
}

.error,
.invalid {
  color: #99172a;
}

.purple-background {
  background-color: #36284c;
}

.blue-background {
  background-color: #6d8cc3;
}

.eggplant-background {
  background-color: #5f4158;
}

.rose-background {
  background-color: #c296a8;
}

.gold-background {
  background-color: #efb065;
}

/* BLOCK STYLES */

.underline {
  border-bottom: 1px solid #36284c;
}

.hidden {
  display: none;
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.sharp-canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: calc(calc(100vh - 72px) - 42px);
  box-sizing: border-box;
  margin-top: 72px;
}

.offscreen {
  position: absolute;
  left: -1000000px;
  top: -1000000px;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #95337b;
}

.arrow-up.rose {
  border-bottom: 10px solid #c296a8;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #95337b;
}

.arrow-down.white {
  border-top: 10px solid #fff;
}

.clickable {
  cursor: pointer;
}

.neutral-clickable {
  cursor: default;
}

.not-clickable {
  cursor: not-allowed;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.left {
  float: left;
}

.right {
  float: right;
}

.hidden-slider {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.visible-slider {
  max-height: 80vh;
  transition: all 0.8s ease-in-out;
}

.ordered-list {
  margin: 1em 0;
  display: block;
}

.ordered-list-item {
  display: grid;
  grid-template-columns: 2em 1fr;
  margin: 0.5em 0;
}

.flex-cell {
  display: flex;
  flex-direction: column;
}

.left-align-col {
  text-align: left;
  align-items: flex-start;
}

.center-align-col {
  text-align: center;
  align-items: center;
}

.right-align-col {
  text-align: right;
  align-items: flex-end;
}

.vertical-padded-cell {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.horizontal-padded-cell {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.padded-cell {
  margin: 1em;
}

.big-vertical-padded-cell {
  margin: 2.5em 0;
}

.vertical-centered-cell {
  justify-content: center;
}

.indented-col,
.indent {
  margin-left: 1.5em;
}

.tiny-bullet {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 0.2em;
}

.left {
  float: left;
}

.right {
  float: right;
}

.custom-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.custom-scroll::-webkit-scrollbar:vertical {
  width: 11px;
}

.custom-scroll::-webkit-scrollbar:horizontal {
  height: 11px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.3);
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.pixelated-background {
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.big-icon {
  width: 5em;
  margin: 0 2em;
}

@media only screen and (max-width: 768px) {
  .page {
    margin-top: 55px;
    height: auto;
    min-height: calc(100vh - 55px);
  }
}

@media only screen and (max-width: 768px) {
  /* .only-mobile {
        display: block;
    } */

  .only-desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 768.1px) {
  .only-mobile {
    display: none !important;
  }

  /* .only-desktop {

    } */
}

.slider {
  width: 60%;
}

.slider-track {
  top: 6px;
  bottom: 12.5px;
  height: 2px;
  background-color: #bbb;
  border-radius: 5px;
}

.slider-thumb {
  height: 10px;
  width: 10px;
  background-color: transparent;
  border: 2px solid #efb065;
  color: transparent;
  border-radius: 50%;
  cursor: grab;
}

.slider-thumb:focus {
  outline: 0;
}

.slider-label-container {
  width: 60%;
  margin: 0 auto;
  text-align: left;
  /* margin-bottom: -1em; */
}

