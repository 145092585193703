.gallery-page {
  overflow: hidden;
  box-sizing: border-box;
}

.gallery-display {
  max-height: 72vh; /* TODO: change this */
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (min-width: 769px) {
  div.gallery-page {
    height: calc(calc(100vh - 72px) - 42px);
  }
}

.gallery-controls-container {
  position: absolute;
  width: 100%;
  height: 80px;
  bottom: 0;
}

.gallery-controls {
  background-color: #6d8cc3;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 400px 1fr 1fr;
}

.gallery-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-options {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-options-header {
  align-items: center;
}

.gallery-options-radio {
  align-items: flex-start;
  flex-wrap: wrap;
}

div.gallery-no-controls,
div.gallery-background {
  height: calc(calc(100vh - 72px) - 42px);
  width: 100%;
}

div.gallery-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 768px) {
  div.gallery-page {
    padding: 0;
  }

  div.gallery-no-controls,
  div.gallery-background {
    height: calc(calc(100vh - 55px) - 80px);
  }
}
