.selectable-tab {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.4em;
  margin: 0.1em;
  cursor: pointer;
  transition: 0.4s;
  width: 5em;
  background-color: #6d8cc3;
  color: #fff;
}

.selectable-tab:hover {
  background-color: #84a3d9;
}

.selectable-tab.active-tab,
.selectable-tab.active-tab:hover {
  background-color: #efb065;
}
