.progress-marker-container {
  width: 9em;
  position: absolute;
  display: inline-block;
  top: 1em;
  font-size: 1em;
}

.progress-marker-container.small {
  width: 6em;
}

.progress-marker-container.small p {
  font-size: 0.9em;
  margin-top: 0.05em;
  margin-left: 0.4em;
}

.progress-marker {
  position: relative;
  width: 100%;
}

.progress-marker-image {
  width: 100%;
}

.progress-marker-text {
  position: absolute;
  left: 20%;
  top: 15%;
  color: #fff;
}

.progress-marker-text.small-marker-text {
  left: 30%;
}
