.reorder-table {
  display: grid;
  width: 90%;
  grid-template-columns: 20% 40% 25% 15%;
  margin: 0.5em 0;
}

.reorder-content {
  margin: 2em 0;
}

.reorder-table-container {
  height: 30vh;
  width: 100%;
  overflow: auto;
}

.reorder-button-section {
  width: 100%;
  box-sizing: border-box;
  padding: 0 2em;
  height: 5em;
}

.reorder-button-wrapper {
  float: right;
}

@media only screen and (max-width: 768px) {
  div.reorder-table-container {
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  div.reorder-button-section {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  div.reorder-content {
    margin-bottom: 0;
  }

  p.large-text.reorder-table-header {
    font-size: 1.1em;
  }

  p.large-text.reorder-table-text {
    font-size: 1em;
  }

  div.reorder-table {
    grid-template-columns: 20% 25% 30% 25%;
  }

  div.reorder-thumbnail {
    margin: 5px;
  }
}
