.progress-bar-container {
  position: relative;
  max-width: 16em;
  height: 0.6em;
  margin: 0 auto;
  display: block;
}

.progress-bar-track {
  background-color: #ddd;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.progress-bar-full {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #c296a8;
  border-radius: 10px;
  transition: 0.4s linear;
}

.progress-bar-full.slow-progress-bar {
  transition: 0.6s linear;
}

.progress-bar-full.medium-progress-bar {
  transition: 0.4s linear;
}

.progress-bar-full.fast-progress-bar {
  transition: 0.2s linear;
}
