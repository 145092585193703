.order-details-content {
  display: grid;
  width: 70%;
  grid-template-columns: 60% 40%;
}

.order-details-body {
  display: flex;
  flex-direction: row;
  position: relative;
}

.order-detail-table-container {
  max-height: 40vh;
  overflow: auto;
}

.order-detail-table {
  display: grid;
  width: 95%;
  grid-template-columns: 30% 30% 20% 20%;
  margin: 0.5em 0;
}

.order-details-main {
  width: 70%;
  box-sizing: border-box;
  display: inline-block;
}

.order-details-aside {
  margin-top: 40px;
  width: 30%;
  box-sizing: border-box;
  display: inline-block;
}

@media only screen and (max-width: 1000px) {
  p.large-text.order-detail-table-header {
    font-size: 1.1em;
  }

  p.large-text.order-detail-text {
    font-size: 1em;
  }

  p.large-text.item-detail-size {
    font-size: 1em;
  }

  div.account-subpage.order-details {
    padding: 30px;
  }
}

@media only screen and (max-width: 880px) {
  div.order-details-body {
    flex-wrap: wrap;
    margin-top: 200px;
  }

  div.order-details-main {
    width: 100%;
  }

  aside.order-details-aside {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  div.order-details-body {
    margin-top: 0;
  }
}

@media only screen and (max-width: 600px) {
  div.order-details-content {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 500px) {
  div.order-details-content {
    width: 90%;
  }

  div.order-detail-table {
    grid-template-columns: 25% 25% 25% 25%;
  }
}
