.review-step {
  width: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-header {
  position: relative;
}

.review-content-container {
  position: relative;
  width: 90%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.review-image-container {
  width: 40%;
  max-height: 60vh;
  margin: 2%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.review-image-wrapper {
  margin: 5%;
  position: relative;
  box-sizing: border-box;
}

.review-image.landscape-review-image {
  width: 250px;
  height: auto;
}

.review-image.portrait-review-image {
  height: 250px;
  width: auto;
}

.review-preview-section {
  min-width: 50%;
  width: auto;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

.review-preview-content {
  width: 90%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
}

.vertical-image-selector {
  width: 30%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  position: relative;
  padding: 0 10% 0 2%;
  margin-top: 5%;
}

.image-thumbnail-placeholder {
  width: 100%;
  height: 2em;
  background-color: green;
  margin: 0.5em 0;
}

.brix-review-info-container {
  margin-top: 0.5em;
}

.brix-review-size-container {
  width: 100%;
  height: 1.3em;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@media only screen and (max-width: 768px) {
  .review-step {
    max-height: none;
  }

  .review-header {
    font-size: 28px;
    text-align: center;
    padding: 0 20px;
  }

  .review-content-container {
    flex-wrap: wrap;
  }

  .review-image-container,
  .review-preview-section {
    width: 100%;
    max-height: none;
  }

  .review-preview-section {
    align-items: center;
    margin-bottom: 30px;
  }

  .review-preview-content {
    margin-top: 20px;
  }

  .review-image-wrapper {
    margin: 1%;
    width: 100%;
  }

  .review-image.portrait-review-image,
  .review-image.landscape-review-image {
    width: 80%;
    height: auto;
  }
}
