.blade-manager {
  --blade-width: 3em;

  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  z-index: 0;
  overflow: hidden;
}

.blade-manager-page {
  position: absolute;
  transition: 1s ease-in-out;
}

.blade-manager-page.left {
  transform: translateX(calc(-1 * calc(100% - var(--blade-width))));
}

.blade-manager-page.right {
  transform: translateX(calc(100% - var(--blade-width)));
}
