.cart-page {
  position: relative;
  height: 84vh;
  box-sizing: border-box;
  width: 100%;
  background-image: url('https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png');
  background-position: center;
  background-size: cover;
}

.shopping-cart {
  text-align: center;
  width: 100%;
  height: 74vh;
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.empty-cart-message {
  min-width: 50%;
  max-width: 30em;
}

.cart-item-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
  max-height: 400px;
  position: relative;
}

.cart-heading-container {
  width: 100%;
}

.cart-heading {
  display: grid;
  width: 90%;
  margin: 1% 0;
  grid-template-columns: 25% 35% 20% 20%;
}

.cart-grid-container {
  position: relative;
  max-height: 52vh;
  overflow-y: auto;
  width: 100%;
}

.cart-grid {
  display: grid;
  width: 90%;
  max-height: inherit;
  margin: 1% 0;
  grid-template-columns: 25% 35% 20% 20%;
}

.cart-total-grid-container {
  width: 100%;
}

.cart-total-grid {
  display: grid;
  width: 90%;
  height: 4vh;
  margin-top: 5%;
  grid-template-columns: 80% 20%;
  box-sizing: border-box;
}

.grid-cell {
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart-item-container {
  margin: 2em;
  box-sizing: border-box;
  width: 100%;
}

.cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1em;
  padding: 1em 2em;
  border: 1px solid grey;
}

.cart-display-photo {
  width: 40%;
  margin: 5% auto;
}

.brix-text-container {
  text-align: left;
}

.continue-shopping-section {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 1em;
  height: 3em;
  box-sizing: border-box;
  margin-top: auto;
}

.to-precheckout-button {
  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  div.cart-item-section {
    padding: 2% 0;
  }

  div.cart-heading,
  div.cart-grid {
    width: 100%;
  }

  img.cart-display-photo {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  div.cart-heading > div > p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  div.cart-heading,
  div.cart-grid {
    grid-template-columns: 25% 25% 30% 20%;
  }

  p.large-text.brix-size {
    font-size: 1rem;
  }
}
