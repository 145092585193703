.error-message {
  color: #de0235;
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  text-align: left;
  width: 100%;
}

.error-message.large-error {
  font-size: 1.2em;
  text-align: center;
  padding: 0.4em 0.2em;
}
