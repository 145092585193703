footer {
  height: 42px;
  overflow-y: auto;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 0.8fr 1.6fr 12em;
  z-index: 100;
  background-color: #fff;
}

.quick-links-area {
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: flex-start;
  padding: 0 5%;
  box-sizing: border-box;
}

.quick-link-container {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2em;
}

.footer-shipping-note {
  height: inherit;
  width: 100%;
  margin: 0 1em;
}

.social-area {
  margin-left: auto;
  height: inherit;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.social-icon-box {
  display: inline-flex;
  height: inherit;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.social-icon {
  height: 40px;
}

.social-link {
  position: relative;
  display: inline-block;
  height: 6vh;
}

@media only screen and (max-width: 930px) {
  .quick-links-area,
  .footer-shipping-note {
    text-align: center;
  }
}

/* for tablets */
@media only screen and (max-width: 768px) {
  footer,
  .quick-links-area,
  .quick-link-container,
  .footer-shipping-note,
  .social-icon-box {
    height: 80px;
  }
}

/* for mobile */
@media only screen and (max-width: 600px) {
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    height: auto;
  }

  .quick-links-area {
    justify-content: center;
    height: auto;
  }

  .quick-link-container {
    padding: 0.8em 0 0.2em 0;
    height: auto;
  }

  .footer-shipping-note {
    height: auto;
    padding: 0.2em 0;
  }

  .social-area,
  .social-icon-box {
    margin: 0.1em 0;
    height: auto;
  }
}
